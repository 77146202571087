import DateHelper from "helpers/date-helper";
import LocalStorageHelper from "helpers/local-storage-helper";
import UrlHelper from "helpers/url-helper";

export enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
}

export enum Method {
  Get = "GET",
  Post = "POST",
  Patch = "PATCH",
  Delete = "DELETE",
  Put = "PUT",
}

enum Mode {
  Cors = "cors",
  NoCord = "no-cors",
  SameOrigin = "same-origin",
}

/* deprecated, use ApiResponse instad */
export interface APIResult {
  success: boolean;
  errorMessage?: string;
  content: any;
}

export interface ApiResponse {
  status: ApiResponseStatus;
  code: ErrorCode;
  message: string;
  version?: string;

  result?: any;
  meta_data?: any;
}

export enum ApiResponseStatus {
  OK = "OK",
  Error = "ERROR",
}

export enum ErrorCode {
  NoError = 0,
  Error = 901,
  UnexpectedError = 902,
  AssetNotFound = 910,
  AuthFailure = 1001,
  ConfigurationMissing = 2001,
  DeviceNotFound = 3001,
  EventNotAllowed = 4001,
  ProcessingScanNotFound = 5001,
  ProcessingFolderInvalid = 5002,
  ProcessingEventInvalid = 5003,
  ProcessingEventNotAllowed = 5004,
  ProcessingNotNeeded = 5005,
  PocessingRunNotFound = 5006,
  ProvisioningTokenNotFound = 6001,
  ProvisioningScannerNotFound = 6002,
  ScanNotFound = 7001,
}

abstract class APIHelper {
  abstract getRoot(): string;
  abstract getAccesstokenKey(): string | null;
  abstract getUserCredentialsKey(): string | null;

  abstract preHook(): Promise<any>;
  abstract postHook(): Promise<any>;

  isMuted = false;

  protected timeoutPromise(ms: number, promise: Promise<any>) {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error(`Timeout after ${ms}ms`));
      }, ms);
      promise.then(
        (res) => {
          clearTimeout(timeoutId);
          resolve(res);
        },
        (err) => {
          clearTimeout(timeoutId);
          reject(err);
        }
      );
    });
  }

  protected getHeaders() {
    let headers: any = {
      "Content-Type": "application/json",
    };

    if (this.hasAccessToken()) {
      let token = this.getAccessToken();
      headers.Authorization = `Bearer ${token}`;
    }

    return headers;
  }

  mute(isMuted: boolean = true) {
    this.isMuted = isMuted;
  }

  toApiDate(date: Date) {
    return DateHelper.toISO(date);
  }

  hasAccessToken() {
    const token = this.getAccessToken();
    return token != null;
  }

  setAccessToken(token: string) {
    const key = this.getAccesstokenKey();
    if (key != null) {
      LocalStorageHelper.set(key, token);
    }
  }

  getAccessToken() {
    let token: string | null = null;
    const key = this.getAccesstokenKey();
    if (key != null) {
      token = LocalStorageHelper.get(key);
    }
    return token;
  }

  clearAccessToken() {
    const key = this.getAccesstokenKey();
    if (key != null) {
      LocalStorageHelper.clear(key);
    }
  }

  setUserCredentials(credentials: string) {
    const key = this.getUserCredentialsKey();
    if (key != null) {
      LocalStorageHelper.set(key, credentials);
    }
  }

  getUserCredentials() {
    let token: string | null = null;
    const key = this.getUserCredentialsKey();
    if (key != null) {
      token = LocalStorageHelper.get(key);
    }
    return token;
  }

  clearUserCredentials() {
    const key = this.getUserCredentialsKey();
    if (key != null) {
      LocalStorageHelper.clear(key);
    }
  }

  async post(endpoint: string, queryParams?: any, body?: any): Promise<ApiResponse> {
    return this.call(Method.Post, endpoint, queryParams, body);
  }

  async put(endpoint: string, queryParams?: any, body?: any): Promise<ApiResponse> {
    return this.call(Method.Put, endpoint, queryParams, body);
  }

  async delete(endpoint: string, queryParams?: any, body?: any): Promise<ApiResponse> {
    return this.call(Method.Delete, endpoint, queryParams, body);
  }

  async get(endpoint: string, queryParams?: any): Promise<ApiResponse> {
    return this.call(Method.Get, endpoint, queryParams);
  }

  async getFile(endpoint: string): Promise<Blob | null> {
    const path: string = this.path(endpoint);
    const params = {
      method: Method.Get,
      mode: Mode.Cors,
      headers: this.getHeaders(),
    };

    const result = await fetch(path, params)
      .then((response: any) => {
        if (!response.ok) {
          return Promise.reject(`${response.status}: ${response.statusText}`);
        }
        return response.blob();
      })
      .catch((error) => {
        console.log("fetching error:", error);
        return null;
      });

    return result;
  }

  private path(endpoint: string) {
    let root = this.getRoot();
    return `${root}/${endpoint}`;
  }

  private async call(method: Method, endpoint: string, queryParams?: any, body?: any): Promise<ApiResponse> {
    await this.preHook();

    let path: string = this.path(endpoint);
    const headers = this.getHeaders();

    if (queryParams) {
      path += "?" + UrlHelper.toQueryString(queryParams);
      //we need to parse it
    }

    if (queryParams && !this.isMuted) {
      console.log(`api-call(${endpoint})`, "query params", queryParams);
    }

    let params: any = {};
    params.method = method;
    params.mode = Mode.Cors;
    params.headers = headers;
    if (body) {
      if (!this.isMuted) {
        console.log(`api-call(${endpoint})`, "query body", body);
      }
      params.body = JSON.stringify(body);
    }

    let result = await fetch(path, params)
      .then((response) => {
        if (response.status === 204 || response.status === 201) {
          return this.handlePossibleEmptyResponse(response);
        } else {
          return this.handleResponse(response);
        }
      })
      .catch((error) => {
        console.warn(`api-call(${endpoint})`, "response error", error);
        return {
          status: ApiResponseStatus.Error,
          code: ErrorCode.Error,
          message: "could not fetch",
          content: null,
        } as ApiResponse;
      });

    if (!this.isMuted) {
      console.log(`api-call(${endpoint})`, "response", result);
    }
    await this.postHook();

    return result;
  }

  async getAssetByUrl(url: string): Promise<Blob | null> {
    const path = url.replace(this.getRoot(), "").replace("/", "");
    return await this.getFile(path);
  }

  async getObjectURL(url: string): Promise<string> {
    const response = await this.getAssetByUrl(url);
    return response ? URL.createObjectURL(response) : "";
  }

  protected async handleResponse(response: any): Promise<ApiResponse> {
    return await response.json();
  }

  private async handlePossibleEmptyResponse(response: any): Promise<ApiResponse> {
    try {
      const result = await response.json();
      return {
        status: ApiResponseStatus.OK,
        code: ErrorCode.NoError,
        message: "OK",
        content: result,
      } as ApiResponse;
    } catch (e: any) {
      return {
        status: ApiResponseStatus.OK,
        code: ErrorCode.NoError,
        message: "OK",
        content: { success: true },
      } as ApiResponse;
    }
  }
}

export default APIHelper;
