import { EuiImage } from "@elastic/eui";

export type MMIconType = "referral" | "care-need" | "referrer" | "indication" | "characteristic" | "usage";
export interface MMIconProps {
  type: MMIconType;
}

function MMIcon(props: MMIconProps) {
  return (
    <EuiImage
      alt={props.type}
      src={`/icons/${props.type}.svg`}
      style={{ display: "inline", position: "relative", top: "-2px" }}
    />
  );
}

export default MMIcon;
