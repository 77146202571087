import { EuiButton, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useNavigate } from "react-router-dom";
import MMScansList from "./scans-list";

function MMScans() {
  const groups = AuthenticationHelper.getGroups();

  const navigate = useNavigate();

  let buttons: any = [];
  if (groups.includes("/manometric")) {
    buttons.push(
      <EuiButton color="accent" size="s" iconType="compute" onClick={() => navigate("/scanning/scanners")}>
        {txt.uf("generic.view_x", txt.get("scanning.scanners.page_title"))}
      </EuiButton>
    );
  }

  return (
    <MMPage hideTitle={true} title={txt.get("scanning.scans.page_title")} topActions={buttons}>
      <EuiFlexItem>
        <EuiSpacer />
        <MMScansList />
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMScans;
