import * as Sentry from "@sentry/react";
import { HIDDEN_ROLES } from "api/auth-admin-api-field-options";
import ClientsAPIHelper from "api/clients-api.helper";
import ConnectAPIHelper from "api/connect-api-helper";
import ManoXAPIHelper from "api/manox-api-helper";
import jwt_decode from "jwt-decode";
import Keycloak from "keycloak-js";
import { PersonalDetails } from "store/data/personal-details/personal-details";
import { User } from "store/data/user/user";
import env from "./env-helper";
import LocalStorageHelper from "./local-storage-helper";
import MessageBirdHelper from "./messagebird-helper";
import txt, { DEFAULT_USER_LANGUAGE } from "./text-helper";

const KEYCLOAK_URL = env("REACT_APP_API_AUTH") ?? "https://auth.manometric.cloud/";
const KEYCLOAK_REALM = env("REACT_APP_API_AUTH_REALM") ?? "manometric";
const KEYCLOAK_CLIENT_ID = env("REACT_APP_API_AUTH_CLIENT_ID") ?? "manometric-connect";

const REFRESH_TIMEOUT = 60; // 1 minute

const keycloak: Keycloak = new Keycloak({
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
});

class AuthenticationHelper {
  static permissions: string[] = [];
  static realmRoles: string[] = [];
  static connectApi: ConnectAPIHelper = new ConnectAPIHelper();
  static manoxApi: ManoXAPIHelper = new ManoXAPIHelper();
  static clientsApi: ClientsAPIHelper = new ClientsAPIHelper();
  static personalDetails: PersonalDetails;
  static keycloak = keycloak;

  static init(onSuccess?: Function, onFailure?: Function) {
    this.keycloak
      .init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
      })
      .then(async (authenticated) => {
        if (authenticated) {
          console.warn("[auth-helper@init] Authenticated.");

          this.connectApi.setAccessToken(AuthenticationHelper.getToken());
          this.manoxApi.setAccessToken(AuthenticationHelper.getToken());
          this.clientsApi.setAccessToken(AuthenticationHelper.getToken());
          await AuthenticationHelper.initPersonalDetails();
          await AuthenticationHelper.getPermissions();

          //reset text, as default language might have been updated
          await txt.init(true);

          setTimeout(() => {
            MessageBirdHelper.setLanguage(txt.lang());
            if (this.keycloak.subject) {
              const userInfo = {
                organisation_id: AuthenticationHelper.getOrganisationId(),
                first_name: AuthenticationHelper.getFirstName(),
                last_name: AuthenticationHelper.getLastName(),
                email: AuthenticationHelper.getEmail(),
              };
              MessageBirdHelper.identify(this.keycloak.subject, userInfo);
            }
          }, 5 * 1000); // After 5 seconds.

          setInterval(() => {
            keycloak
              .updateToken(REFRESH_TIMEOUT)
              .then((refreshed) => {
                if (refreshed) {
                  console.warn("[auth-helper@init] Token refreshed.");
                } else {
                  console.debug("[auth-helper@init] Token still valid.");
                }
              })
              .catch(() => {
                console.error("[auth-helper@init] Token refresh failed!");
              });
          }, 20 * 1000); // Every 20 seconds.
        }

        if (authenticated && onSuccess) {
          onSuccess(AuthenticationHelper.getUserInfo());
        } else if (!authenticated) {
          // AuthenticationHelper.promptForLogin();
          if (onFailure) onFailure("Could not authenticate!");
        }
      })
      .catch(() => {
        if (onFailure) onFailure("Failed to initialize!");
      });
  }

  static async refreshAuthentication() {
    await this.keycloak.updateToken(REFRESH_TIMEOUT).catch((reason) => {
      console.log("[auth-helper@refresh] Token refresh failed!", reason);
      AuthenticationHelper.relogin();
    });

    return true;
  }

  static promptForLogin(redirectPath?: string) {
    console.log("promptForLogin", redirectPath);

    let config: any = {};
    if (redirectPath) {
      config.redirectUri = window.location.origin + redirectPath;
      config.locale =
        JSON.parse(LocalStorageHelper.get("user_language") || '"' + DEFAULT_USER_LANGUAGE + '"') ||
        DEFAULT_USER_LANGUAGE;
    }
    this.keycloak
      .login(config)
      .then((result) => {
        console.log("promptforlogin", result);
      })
      .catch((message) => console.log("error", message));
  }

  //is called directly after authentication to have the personal details from connect available at all times
  static async initPersonalDetails() {
    const personalDetails: PersonalDetails[] = await this.connectApi.getPersonalDetails(
      AuthenticationHelper.getUserInfo().email
    );
    if (personalDetails && personalDetails.length > 0) {
      AuthenticationHelper.personalDetails = personalDetails[0];

      Sentry.setUser({
        username: AuthenticationHelper.personalDetails?.username || undefined,
        email: AuthenticationHelper.personalDetails?.email || undefined,
        id: AuthenticationHelper.personalDetails?.id || undefined,
      });
    }
  }

  static getPersonalDetails() {
    return AuthenticationHelper.personalDetails;
  }
  static getPersonalDetailsId() {
    return AuthenticationHelper.personalDetails?.id;
  }
  static getUserInfo() {
    // console.log('getUserInfo()');
    const user: User = {
      google_id: this.getToken(),
      email: this.getEmail(),
      google_credential: this.getToken(),
      name: this.getFullName(),
      thumbnail: "",
    };

    return user;
  }

  static async getPermissions() {
    if (AuthenticationHelper.permissions.length === 0) {
      const manoxApiresult = await AuthenticationHelper.manoxApi.getPermissions();
      const connectApiResult = await AuthenticationHelper.connectApi.getPermissions();
      const clientsApiResult = await AuthenticationHelper.clientsApi.getPermissions();

      AuthenticationHelper.permissions = (manoxApiresult || [])
        .concat(connectApiResult || [])
        .concat(clientsApiResult || []);
    }
    return AuthenticationHelper.permissions;
  }

  static getRealmRoles() {
    // console.log("getRealmRoles", this.keycloak.tokenParsed?.resource_access);
    if (AuthenticationHelper.realmRoles.length === 0) {
      if (
        this.keycloak &&
        this.keycloak.tokenParsed &&
        this.keycloak.tokenParsed.resource_access &&
        this.keycloak.tokenParsed.resource_access["realm-management"] &&
        this.keycloak.tokenParsed.resource_access["realm-management"].roles
      ) {
        AuthenticationHelper.realmRoles = this.keycloak.tokenParsed.resource_access["realm-management"].roles;
      }
    }
    return AuthenticationHelper.realmRoles;
  }

  static getAuthClient() {
    return this.keycloak;
  }

  static relogin() {
    console.log("relogin", window.location.href);
    const redirectTo = `${window.location.href}${window.location.search}`;

    if (window.location.href === window.location.origin) {
      AuthenticationHelper.promptForLogin(redirectTo);
    } else {
      this.keycloak
        .logout({
          redirectUri: window.location.origin,
        })
        .then((result) => {
          console.log("logged out", result);
          AuthenticationHelper.promptForLogin(redirectTo);
        })
        .catch((message) => console.log("error", message));
    }
  }

  static logout() {
    MessageBirdHelper.logout();
    this.keycloak
      .logout({
        redirectUri: window.location.origin,
      })
      .then((result) => {
        console.log("logout", result);
      })
      .catch((message) => console.log("error", message));
  }

  static isAuthenticated() {
    return this.keycloak.authenticated;
  }

  static getToken() {
    return this.keycloak.token ?? "";
  }

  static getRemainingTokenTime() {
    const token = this.keycloak.token ?? "";

    if (!token) {
      return 0;
    }

    try {
      const decoded: any = jwt_decode(token);

      const currentTime = Math.floor(Date.now() / 1000);
      const remaining = decoded.exp - currentTime;

      return remaining > 0 ? remaining : 0;
    } catch (error) {
      console.error("[auth-helper@remaining] Invalid token!", error);

      return 0;
    }
  }

  static getEmail() {
    return this.keycloak.tokenParsed?.email;
  }
  static getUsername() {
    return this.keycloak.tokenParsed?.preferred_username;
  }

  static getFirstName() {
    return this.keycloak.tokenParsed?.given_name;
  }

  static getLastName() {
    return this.keycloak.tokenParsed?.family_name;
  }

  static getFullName() {
    return this.keycloak.tokenParsed?.name;
  }

  static hasRole(role: string) {
    return !!this.keycloak.realmAccess?.roles ? this.keycloak.realmAccess?.roles.includes(role) : false;
  }

  static getRoles() {
    if (!!this.keycloak.realmAccess?.roles) {
      return this.keycloak.realmAccess?.roles.filter((role) => !HIDDEN_ROLES.includes(role));
    }

    return [];
  }

  static getGroupNames() {
    return this.keycloak.idTokenParsed?.groups || [];
  }

  static getGroups() {
    const groups = this.keycloak.idTokenParsed?.groups || [];
    let result: any[] = [];
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].indexOf("/", 1)) {
        let subGroups: string[] = groups[i].split("/");
        let subGroupGrouped: string = "";
        for (let j = 0; j < subGroups.length; j++) {
          const subGroup = subGroups[j];
          if (subGroup === "/" || !subGroup) {
          } else {
            subGroupGrouped += `/${subGroups[j]}`;
            result.push(subGroupGrouped);
          }
        }
      }
    }
    return result;
  }

  static isInGroup(group: string) {
    return this.getGroups().includes(group);
  }

  static async hasPermission(permission: string | string[]) {
    const permissionsToCheck: string[] = Array.isArray(permission) ? permission : [permission];
    const grantedPermissions: string[] = await AuthenticationHelper.getPermissions();
    let anyPermitted: boolean = false;
    for (let i = 0; i < permissionsToCheck.length; i++) {
      const permission = permissionsToCheck[i];
      if (grantedPermissions.includes(permission)) {
        anyPermitted = true;
        break;
      }
    }

    return anyPermitted;
  }

  static hasRealmRole(roles: string | string[]) {
    const rolesToCheck: string[] = Array.isArray(roles) ? roles : [roles];

    const grantedRealmRoles: string[] = AuthenticationHelper.getRealmRoles();
    let anyPermitted: boolean = false;
    for (let i = 0; i < rolesToCheck.length; i++) {
      const role = rolesToCheck[i];
      if (grantedRealmRoles.includes(role)) {
        anyPermitted = true;
        break;
      }
    }
    console.log("hasRealmRole", anyPermitted, rolesToCheck, grantedRealmRoles);
    return anyPermitted;
  }

  static isDemo() {
    return this.keycloak.idTokenParsed?.is_demo || null;
  }

  static getOrganisationId() {
    return this.keycloak.idTokenParsed?.organisation_id || null;
  }

  static getLanguageCode() {
    console.log("AuthenticationHelper.getLanguageCode", this.keycloak.idTokenParsed?.language_code);
    return this.keycloak.idTokenParsed?.language_code || null;
  }
  static isInternalUser() {
    return AuthenticationHelper.isInternalOrganisationId(AuthenticationHelper.getOrganisationId());
  }

  static getInternalOrganisationId() {
    return parseInt(env("REACT_APP_MANOMETRIC_ORGANISATION_ID"));
  }

  static isInternalOrganisationId(organisationId: string | number) {
    return organisationId ? organisationId.toString() === env("REACT_APP_MANOMETRIC_ORGANISATION_ID") : true;
  }
}

export default AuthenticationHelper;
