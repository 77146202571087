import {
  EuiCheckbox,
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiTitle,
  EuiToolTip,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMAITextToSentence from "components/layouts/aitools/text-to-sentence";
import MMIcon, { MMIconType } from "components/layouts/icon/icon";
import MMTip from "components/layouts/tip/tip";
import { feat, Feature } from "feats";
import AuthenticationHelper from "helpers/authentication-helper";
import { Input } from "helpers/input-helper";
import txt from "helpers/text-helper";
import { toSelectOptions } from "hoc/helper-hooks";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { getNeed, hasNeed, Need, NEED_EMPTY } from "store/data/need/need";
import { ORDER_EMPTY } from "store/data/order/order";
import { MMOrderEntryInputProps } from "../order-entry";
import { validationOfNeedsInput } from "../order-inputs";

type NeedConfiguration =
  | {
      type: "check" | "text" | "error" | "tags";
      key: string;
      title?: string;
      more?: string;
      enabled?: boolean;
      visible?: boolean | Function;
      size?: number;
      padded?: boolean;
      subNeeds?: string[];
    }
  | {
      type: "spacer";
      size?: string;
    }
  | {
      type: "title";
      key: string;
      info?: string;
      icon?: string;
    };

type NeedGroup = {
  needConfigurations: NeedConfiguration[];
};

const LEGACY_MAPPING_TEXTS: any = {
  "usage.explanation": ["care_needs.activities", "usage.other"],
  "characteristics.explanation": ["anatomy.other", "characteristics.other"],
  "care_needs.explanation": ["care_needs.needs"],
};
const LEGACY_MAPPING: any = {
  "care_needs.pain": "anatomy.pain",
  "care_needs.strength": "anatomy.strength",
  "care_needs.mobility": "anatomy.mobility",
  "care_needs.sensibility": "anatomy.sensibility",
};
const TEXTAREA_SIZE_SMALL: number = 2;
const TEXTAREA_SIZE_NORMAL: number = 3;
const TEXTAREA_SIZE_LARGE: number = 6;
const enum ValueType {
  Boolean = "BOOLEAN",
  String = "STRING",
}

function MMOrderNeeds(props: MMOrderEntryInputProps) {
  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const [needs, setNeeds] = useState<Need[]>([]);
  const [needTags, setNeedTags] = useState<any>({});
  const [isCharacteristicsEnabled, setIsCharacteristicsEnabled] = useState<boolean>(true);
  const [isUsageEnabled, setIsUsageEnabled] = useState<boolean>(true);
  const [isTextToSentenceShown, setIsTextToSentenceShown] = useState<boolean>(false);

  const canEditOrCorrectFields: boolean = props.isEditable || props.isCorrigible || false;

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setIsTextToSentenceShown(await AuthenticationHelper.hasPermission(["contracts#read_all", "contracts#edit_all"]));
    };
    setInterfaceForPermissions();
  }, []);

  useEffect(() => {
    const handleNeeds = async (needs: Need[]) => {
      setNeeds((old: Need[]) => needs);
      setIsCharacteristicsEnabled(getNeed(needs, "characteristics.none") !== "true");
      setIsUsageEnabled(getNeed(needs, "usage.none") !== "true");
      if (!needTags || !needTags?.["activities.activities"] || !needTags?.["activities.hobbies"]) {
        const activityTags = await api.getNeedTags("activities.activities");
        const hobbyTags = await api.getNeedTags("activities.hobbies");
        setNeedTags((old: any) => ({
          "activities.activities": activityTags,
          "activities.hobbies": hobbyTags,
        }));
      }
    };
    if (props.order && props.order.needs) {
      handleNeeds(props.order.needs);
    } else {
      setNeeds((old: Need[]) => []);
      setNeedTags((old: any) => {});
    }
  }, [props.order?.needs]);

  const onNeedsChanged = (updatedNeeds: Need[], updateOrder: boolean = true) => {
    if (props.onChange && updateOrder) {
      props.onChange({
        ...ORDER_EMPTY,
        ...props.order,
        needs: updatedNeeds,
      });
    } else {
      setIsCharacteristicsEnabled(getNeed(updatedNeeds, "characteristics.none") !== "true");
      setIsUsageEnabled(getNeed(updatedNeeds, "usage.none") !== "true");

      setNeeds((needs: Need[]) => updatedNeeds);
    }
  };

  const onNeedChange = (question: string, answer: string, updateOrder: boolean = true, subNeeds: string[] = []) => {
    let updatedNeeds: Need[] = [];
    let isNeedFound: boolean = false;

    for (let i = 0; i < needs.length; i++) {
      const need: Need = needs[i];
      if (need.question === question || subNeeds.includes(need.question)) {
        isNeedFound = true;
        if (answer !== "false") {
          updatedNeeds.push({ ...need, answer });
        }
      } else if (
        (question === "characteristics.none" && need.question.startsWith("characteristics")) ||
        (question === "usage.none" && need.question.startsWith("usage"))
      ) {
      } else {
        updatedNeeds.push({ ...need });
      }
    }
    if (!isNeedFound) {
      updatedNeeds.push({ ...NEED_EMPTY, question, answer });
    }

    onNeedsChanged(updatedNeeds, updateOrder);
  };

  const errorMessage = (question: string) => {
    if (!props.hasValidation) return "";

    const order: any = { ...props.order };
    const fields: any = { ...props.inputs?.needs };
    const input: Input | null = fields[question] ? (fields[question] as Input) : null;
    if (!input?.mandatory) return "";

    const validation = validationOfNeedsInput(order, fields, question);
    if (!validation.result) {
      return validation.message;
    }

    if (props.order && props.order?.needs.findIndex((need: Need) => need.question === question && !!need.answer) >= 0) {
      return "";
    }

    return txt.get("validations.not_empty");
  };

  const valueOfNeed = (question: string, valueType: ValueType) => {
    const need: Need | undefined = needs.find((need: Need) => need.question === question);
    if (need) {
      return valueType === ValueType.Boolean ? need.answer === "true" : need.answer;
    }
    return valueType === ValueType.Boolean ? false : "";
  };

  const renderTitle = (questions: string, tipText?: string | ReactNode, icon?: MMIconType) => (
    <EuiFlexItem>
      <EuiTitle size="xs">
        <EuiText color="grey">
          {tipText ? (
            <MMTip text={tipText}>
              {icon ? (
                <span style={{ paddingRight: "8px" }}>
                  <MMIcon type={icon} />
                </span>
              ) : (
                <></>
              )}
              {txt.get(`needs.questions.${questions}.title`)}
            </MMTip>
          ) : (
            txt.get(`needs.questions.${questions}.title`)
          )}
        </EuiText>
      </EuiTitle>
    </EuiFlexItem>
  );

  const toTestId = (question: string) => `input-need-${question.replace(".", "-")}`;

  const renderCheckNeed = (
    question: string,
    moreText?: string,
    padded: boolean = false,
    subNeeds: string[] = [],
    isEnabled: any = true
  ) => {
    const error: string = errorMessage(question);
    let isChecked: boolean = valueOfNeed(question, ValueType.Boolean) as boolean;

    //fallback for older orders before -feature-intake-improvements branch merging 2024-08-07
    if (feat(Feature.OrdersNewIntakeScreens)) {
      let oldChecked: string = "";
      let newChecked: string = "";
      let newQuestions: string[] = Object.keys(LEGACY_MAPPING);
      if (newQuestions.includes(question)) {
        oldChecked = getNeed(needs, LEGACY_MAPPING[question]);
        newChecked = getNeed(needs, question);
        if (newChecked === "" && (oldChecked === "true" || oldChecked === "false")) {
          isChecked = oldChecked === "true";
        }
      }
    }

    return (
      <EuiFlexItem>
        <EuiFormRow
          display="rowCompressed"
          fullWidth={true}
          style={padded ? { marginLeft: "25px" } : {}}
          isInvalid={!!error}
          error={error}
          isDisabled={!isEnabled || !canEditOrCorrectFields}
          data-testid={`validation-error-${question}`}
        >
          <EuiCheckbox
            disabled={!isEnabled || !canEditOrCorrectFields}
            readOnly={!isEnabled || !canEditOrCorrectFields}
            compressed={true}
            id={question}
            data-testid={toTestId(question)}
            name={question}
            label={
              moreText ? (
                <EuiToolTip content={txt.get(`needs.questions.${moreText}`)}>
                  <EuiText size="s" style={{ position: "relative" }}>
                    {txt.html(`needs.questions.${question}`)}
                    <span style={{ position: "absolute" }}>
                      <EuiIcon color="#AAAAAA" size="s" type="iInCircle" />
                    </span>
                  </EuiText>
                </EuiToolTip>
              ) : (
                <EuiText size="s">
                  <EuiText size="s">{txt.html(`needs.questions.${question}`)}</EuiText>
                </EuiText>
              )
            }
            checked={isChecked}
            onChange={(e) => {
              onNeedChange(question, e.target.checked ? "true" : "false", true, !e.target.checked ? subNeeds : []);
            }}
          />
        </EuiFormRow>
        <EuiSpacer size="xs" />
      </EuiFlexItem>
    );
  };

  const onCreateOption = (question: string, value: string) => {
    const normalizedSearchValue = value.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const needValue: string = valueOfNeed(question, ValueType.String) as string;

    const options = toSelectOptions(needValue.split(",").filter((value: string) => value !== ""));

    if (options.findIndex((option: any) => option.label.trim().toLowerCase() === normalizedSearchValue) === -1) {
      setNeedTags({
        [question]: [...(needTags && needTags[question] ? needTags[question] : []), normalizedSearchValue],
      });
      onNeedChange(question, `${needValue},${normalizedSearchValue}`);
    }
  };

  const renderTagsNeed = (question: string, title: string = "") => {
    const error: string = errorMessage(question);

    let tagOptions = needTags[question] ? needTags[question] : [""];
    let tagOptionsSelected = (valueOfNeed(question, ValueType.String) as string)
      .split(",")
      .filter((value: string) => value !== "");

    let options = toSelectOptions(tagOptions);
    let selectedOptions = toSelectOptions(tagOptionsSelected);

    return (
      <EuiFlexItem
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <EuiFormRow
          display="rowCompressed"
          fullWidth={true}
          isInvalid={!!error}
          error={error}
          isDisabled={!canEditOrCorrectFields}
          title={title}
          label={
            title ? (
              <EuiText size="s" color="text" style={{ fontWeight: 600 }}>
                {title}
              </EuiText>
            ) : (
              ""
            )
          }
          data-testid={`validation-error-${question}`}
        >
          <EuiComboBox
            className="needs-combo-box"
            compressed={true}
            aria-label={txt.get(`needs.questions.${question}`)}
            placeholder={txt.get(`needs.questions.${question}`)}
            isDisabled={!canEditOrCorrectFields}
            options={options}
            data-testid={toTestId(question)}
            selectedOptions={selectedOptions}
            onChange={(selected: any) => onNeedChange(question, selected.map((value: any) => value.value).join(","))}
            onCreateOption={(searchValue) => onCreateOption(question, searchValue)}
          />
        </EuiFormRow>
      </EuiFlexItem>
    );
  };

  const renderTextNeed = (
    question: string,
    rows: number = TEXTAREA_SIZE_NORMAL,
    toSentenceIntent?: "need" | "descriptive" | "motivation",
    isEnabled: boolean = true,
    padded: boolean = false
  ) => {
    const error: string = errorMessage(question);

    let value: string = valueOfNeed(question, ValueType.String) as string;

    //fallback for older orders before -feature-intake-improvements branch merging 2024-08-07
    if (feat(Feature.OrdersNewIntakeScreens)) {
      let oldText: string = "";
      let newQuestions: string[] = Object.keys(LEGACY_MAPPING_TEXTS);
      if (newQuestions.includes(question) && !hasNeed(needs, question)) {
        oldText = (
          typeof LEGACY_MAPPING_TEXTS[question] === "string"
            ? [LEGACY_MAPPING_TEXTS[question]]
            : LEGACY_MAPPING_TEXTS[question]
        )
          .map((mapped: string) => getNeed(needs, mapped))
          .filter((answer: string) => answer !== "")
          .join("; ");
        if (oldText !== "") {
          value = oldText;
        }
      }
    }
    let style: any = {
      position: "relative",
      width: "100%",
    };
    if (padded) {
      style.marginLeft = "25px";
      style.width = "calc(100% - 25px)";
    }
    return (
      <EuiFlexItem style={style}>
        <EuiFormRow
          display="rowCompressed"
          isInvalid={!!error}
          error={error}
          isDisabled={!isEnabled || !canEditOrCorrectFields}
          data-testid={`validation-error-${question}`}
        >
          <EuiTextArea
            className="needs-text-area"
            disabled={!isEnabled || !canEditOrCorrectFields}
            readOnly={!canEditOrCorrectFields}
            rows={rows}
            isInvalid={!!error}
            cols={40}
            fullWidth={true}
            onChange={(e: any) => onNeedChange(question, e.target.value, false)}
            onBlur={(e: any) => onNeedChange(question, e.target.value)}
            value={value}
            placeholder={txt.get(`needs.questions.${question}`)}
            data-testid={toTestId(question)}
          />
        </EuiFormRow>
        {isEnabled && isTextToSentenceShown ? (
          <MMAITextToSentence
            style={{ position: "absolute", top: "5px", right: "15px" }}
            text={valueOfNeed(question, ValueType.String) as string}
            intent={toSentenceIntent}
            onResult={(sentence: string) => {
              onNeedChange(question, sentence);
            }}
          />
        ) : (
          <></>
        )}
        <EuiSpacer size="s" />
      </EuiFlexItem>
    );
  };

  const renderGroupedNeedsError = (key: string) => {
    if (!props.hasValidation) return "";

    let isValid: boolean = true;

    const keys: string[] = props.inputs?.needs ? Object.keys(props.inputs?.needs) : [];

    if (
      props.order &&
      keys.includes(key) &&
      props.order.needs.findIndex(
        (need: Need) => need.question.startsWith(key) && need.answer !== "" && need.answer !== "false"
      ) < 0
    ) {
      //if there is no need that is not false or empty, nothing is filledout
      isValid = false;
    }

    return !isValid ? (
      <EuiText size="s" color="danger" data-testid={`validation-error-${key}`}>
        {txt.get("validations.any_not_empty_choose")}
      </EuiText>
    ) : (
      <></>
    );
  };
  const isNeed = (question: string, answer?: string) => {
    const need: Need | undefined = needs.find((need: Need) => need.question === question);
    return need && (answer === undefined || need.answer === answer);
  };

  const needsConfiguration: NeedGroup[] = feat(Feature.OrdersNewIntakeScreens)
    ? [
        {
          needConfigurations: [
            {
              type: "title",
              key: "care_needs",
              info: "orders.order.i.care_needs",
              icon: "care-need",
            },
            { type: "check", key: "care_needs.pain" },
            { type: "check", key: "care_needs.mobility" },
            { type: "check", key: "care_needs.strength" },
            { type: "check", key: "care_needs.sensibility" },
            {
              type: "text",
              key: "care_needs.explanation",
              size: TEXTAREA_SIZE_LARGE,
            },
            { type: "error", key: "care_needs" },
          ],
        },
        {
          needConfigurations: [
            {
              type: "title",
              key: "usage",
              info: "orders.order.i.usage",
              icon: "usage",
            },
            {
              type: "tags",
              key: "activities.activities",
              title: "needs.questions.activities.adl_acronym",
            },
            { type: "spacer", size: "xs" },
            {
              type: "tags",
              key: "activities.hobbies",
              title: "needs.questions.activities.hobbies_title",
            },
            { type: "spacer", size: "xs" },
            {
              type: "check",
              key: "usage.load",
              more: "usage.load_more",
              enabled: isUsageEnabled,
            },
            {
              type: "check",
              key: "usage.water",
              more: "usage.water_more",
              enabled: isUsageEnabled,
            },
            {
              type: "check",
              key: "usage.contra_limited",
              more: "usage.contra_limited_more",
              enabled: isUsageEnabled,
            },
            {
              type: "check",
              key: "usage.other_aids",
              more: "usage.other_aids_more",
              enabled: isUsageEnabled,
            },
            {
              type: "text",
              key: "usage.explanation",
              size: TEXTAREA_SIZE_NORMAL,
              enabled: isUsageEnabled,
            },
            { type: "spacer", size: "xs" },
            { type: "check", key: "usage.none" },
            { type: "error", key: "usage" },
          ],
        },
        {
          needConfigurations: [
            {
              type: "title",
              key: "characteristics",
              info: "orders.order.i.characteristics",
              icon: "characteristic",
            },
            {
              type: "check",
              key: "characteristics.allergies",
              subNeeds: [
                "characteristics.allergies_silver",
                "characteristics.allergies_silicone",
                "characteristics.allergies_nylon",
              ],
              enabled: isCharacteristicsEnabled,
            },
            {
              type: "check",
              key: "characteristics.allergies_silver",
              padded: true,
              visible: () => isNeed("characteristics.allergies", "true"),
              enabled: isCharacteristicsEnabled,
            },
            {
              type: "check",
              key: "characteristics.allergies_silicone",
              padded: true,
              visible: () => isNeed("characteristics.allergies", "true"),
              enabled: isCharacteristicsEnabled,
            },
            {
              type: "check",
              key: "characteristics.allergies_nylon",
              padded: true,
              visible: () => isNeed("characteristics.allergies", "true"),
              enabled: isCharacteristicsEnabled,
            },
            {
              type: "check",
              key: "characteristics.skin_conditions",
              enabled: isCharacteristicsEnabled,
            },
            {
              type: "check",
              key: "characteristics.sweating",
              enabled: isCharacteristicsEnabled,
            },
            {
              type: "check",
              key: "characteristics.abnormalities",
              enabled: isCharacteristicsEnabled,
            },
            {
              type: "text",
              key: "characteristics.abnormalities_explanation",
              enabled: isCharacteristicsEnabled,
              size: TEXTAREA_SIZE_SMALL,
              padded: true,
              visible: () => isNeed("characteristics.abnormalities", "true"),
            },
            {
              type: "text",
              key: "characteristics.explanation",
              size: TEXTAREA_SIZE_NORMAL,
              enabled: isCharacteristicsEnabled,
            },
            { type: "check", key: "characteristics.none" },
            { type: "error", key: "characteristics" },
          ],
        },
      ]
    : [
        {
          needConfigurations: [
            { type: "title", key: "care_needs" },
            { type: "text", key: "care_needs.needs" },
            { type: "text", key: "care_needs.activities" },
            { type: "check", key: "old_product.used" },
            {
              type: "text",
              key: "old_product.description",
              visible: () => isNeed("old_product.used", "true"),
            },
            {
              type: "text",
              key: "old_product.not_used_description",
              visible: () => !isNeed("old_product.used", "true"),
            },
          ],
        },
        {
          needConfigurations: [
            { type: "title", key: "anatomy" },
            { type: "check", key: "anatomy.pain" },
            { type: "check", key: "anatomy.strength" },
            { type: "check", key: "anatomy.mobility" },
            { type: "check", key: "anatomy.sensibility" },
            {
              type: "text",
              key: "care_needs.explanation",
              size: TEXTAREA_SIZE_LARGE,
            },
          ],
        },
        {
          needConfigurations: [
            { type: "title", key: "characteristics" },
            {
              type: "check",
              key: "characteristics.allergies",
              subNeeds: [
                "characteristics.allergies_silver",
                "characteristics.allergies_silicone",
                "characteristics.allergies_nylon",
              ],
            },
            {
              type: "check",
              key: "characteristics.allergies_silver",
              padded: true,
              visible: () => isNeed("characteristics.allergies", "true"),
            },
            {
              type: "check",
              key: "characteristics.allergies_silicone",
              padded: true,
              visible: () => isNeed("characteristics.allergies", "true"),
            },
            {
              type: "check",
              key: "characteristics.allergies_nylon",
              padded: true,
              visible: () => isNeed("characteristics.allergies", "true"),
            },
            {
              type: "check",
              key: "characteristics.skin_conditions",
            },
            {
              type: "check",
              key: "characteristics.sweating",
            },
            {
              type: "text",
              key: "characteristics.other",
              size: TEXTAREA_SIZE_NORMAL,
            },
            { type: "title", key: "usage" },
            {
              type: "check",
              key: "usage.load",
            },
            {
              type: "check",
              key: "usage.hygiene",
            },
            {
              type: "check",
              key: "usage.chemicals",
            },
            {
              type: "text",
              key: "usage.other",
            },
          ],
        },
      ];

  const renderConfiguration = (config: any) => {
    let visible: boolean = true;
    if (typeof config.visible === "boolean") {
      visible = config.visible !== false;
    } else if (typeof config.visible === "function") {
      visible = config.visible();
    }
    if (visible) {
      if (config.type === "title") {
        return (
          <div style={{ width: "100%" }}>
            {renderTitle(config.key, txt.html(config.info), config.icon)}
            <EuiHorizontalRule className="rule-strong" margin="xs" />
            <EuiSpacer size="xs" />
          </div>
        );
      } else if (config.type === "check") {
        return renderCheckNeed(config.key, config.more, config.padded, config.subNeeds, config.enabled);
      } else if (config.type === "text") {
        return renderTextNeed(config.key, config.size, undefined, config.enabled, config.padded);
      } else if (config.type === "error") {
        return renderGroupedNeedsError(config.key);
      } else if (config.type === "tags") {
        return renderTagsNeed(config.key, txt.get(config.title));
      } else if (config.type === "spacer") {
        return <EuiSpacer size={config.size || "xs"} />;
      }
    }
    return <></>;
  };

  return (
    <EuiFlexGroup gutterSize="xl" alignItems="flexStart">
      {needsConfiguration.map((configGroup: any, i: number) => (
        <EuiFlexItem key={`needs-group-${i}`} grow={true} style={{ minWidth: "275px" }}>
          <EuiFlexGroup direction="column" gutterSize="s" justifyContent="flexStart" alignItems="flexStart">
            {configGroup.needConfigurations.map((needConfiguration: any, j: number) => (
              <Fragment key={`need-configuration-${j}`}>{renderConfiguration(needConfiguration)}</Fragment>
            ))}
          </EuiFlexGroup>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
}

export default MMOrderNeeds;
