import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMSecureImage from "components/layouts/image/secure-image";
import MMTip from "components/layouts/tip/tip";
import { NO_IMAGE, renderTitle } from "components/products/product-card";
import { feat, Feature } from "feats";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Order } from "store/data/order/order";
import { ProductUse } from "store/data/product/product";
import { OrderLineInputs } from "../order-inputs";
import { EMPTY_PRODUCT_INPUT, MMOrderLineProductInput, ProductChangeHandler } from "../order-line-entry";
import MMOrderArgumentation from "./order-argumentation";
import MMOrderDigits from "./order-digits";
import MMOrderFunctionDescription from "./order-function-description";
import MMOrderHand from "./order-hand";
import MMOrderIsModification from "./order-is-modification";
import MMOrderLineAttachments from "./order-line-attachments";
import MMOrderMeasurements from "./order-measurements";
import MMOrderNotes from "./order-notes";
import MMOrderPreviousAid from "./order-previous-aid";
import MMOrderProduct from "./order-product";
import MMOrderProductDescription from "./order-product-description";
import { OnProductLine } from "./order-product-select";
import MMRemakeReason from "./order-remake-reason";
import MMOrderReplacementFor from "./order-replacement-for";
import MMOrderType from "./order-type";
import MMOrderUse from "./order-use";

export interface MMOrderProductSelectDetailsProps {
  order: Order | null;
  productOption: any;
  products: any[];
  onCancel: OnProductLine;
  onSelect?: OnProductLine;
  onDelete?: OnProductLine;
  onDuplicate?: OnProductLine;
  api: ConnectAPIHelper;
  isEditable: boolean;
  isCorrigible?: boolean;
  orderTypes?: any[];
  remakeReasons?: any[];
  clientProductions?: any[];
  showProductionDetails?: boolean;
  hasValidation?: boolean;
  orderLineInputs?: OrderLineInputs;
}

function MMOrderProductSelectDetails(props: MMOrderProductSelectDetailsProps) {
  const [selectionBeingDetailed, setSelectionBeingDetailed] = useState<MMOrderLineProductInput>(
    props.productOption.data_order_line || {
      ...EMPTY_PRODUCT_INPUT,
      family: props.productOption.value,
    }
  );

  const [orderLineIndex] = useState<number | null>(props.productOption.data_order_line_index);
  const [isEditingOrderLine, setIsEditingOrderLine] = useState<boolean>(
    props.productOption.data_order_line_index !== null &&
      props.productOption.data_order_line_index !== undefined &&
      !isNaN(props.productOption.data_order_line_index)
  );
  const [isDetailedSelectionComplete, setIsDetailedSelectionComplete] = useState<boolean>(false);

  const [isNeedsVisible, setIsNeedsVisible] = useState<boolean>(false);

  //temp to figure out if a product is in portfolio
  const [isNotInPortfolio, setIsNotInPortfolio] = useState<boolean>(false);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setIsNeedsVisible(
        await AuthenticationHelper.hasPermission(["needs#read_all", "needs#read_org", "needs#read_all"])
      );
    };
    setInterfaceForPermissions();
  }, []);

  useEffect(() => {
    //no active variation found anymore for the selected product
    console.log("props.productOption.data_order_line", props.productOption.data_order_line);
    setIsNotInPortfolio(
      props.productOption.data_order_line &&
        props.productOption.data_order_line.code &&
        !props.productOption.data_order_line.variation
    );
  }, [props.productOption.data_order_line]);

  useEffect(() => {
    console.log("MMOrderProductSelectDetails", props.productOption, props.productOption.data_order_line_index);
    setIsEditingOrderLine(!isNaN(props.productOption.data_order_line_index));
  }, [props.productOption]);

  useEffect(() => {
    setIsDetailedSelectionComplete(
      !!selectionBeingDetailed.hand &&
        !!selectionBeingDetailed.color &&
        !!selectionBeingDetailed.name &&
        !!selectionBeingDetailed.size
      //  &&
      // !!selectionBeingDetailed.digits &&
      // selectionBeingDetailed.digits.length > 0
    );
  }, [selectionBeingDetailed]);

  const onProductChanged: ProductChangeHandler = (orderLine: MMOrderLineProductInput) => {
    console.log("onProductChange", orderLine);
    if (selectionBeingDetailed.code !== orderLine.code && orderLine.costing) {
      orderLine.costing.contract = null;
    }
    if (!orderLine.id && !orderLine.use) {
      orderLine.use = ProductUse.Day;
    }
    setSelectionBeingDetailed(orderLine);
  };

  <EuiTitle size="xxxs">
    <EuiText>{props.productOption.label}</EuiText>
  </EuiTitle>;

  return (
    <EuiModal
      style={{ backgroundColor: "#eeeeee" }}
      onClose={(e) => props.onCancel(selectionBeingDetailed)}
      initialFocus="[name=popswitch]"
      className={
        feat(Feature.OrdersNewIntakeScreens)
          ? "product-detail-modal"
          : "product-detail-modal product-detail-modal-legacy"
      }
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem
              grow={false}
              className="product-select rounded-image"
              style={{
                width: "55px",
                height: "calc(calc(150 / 138) * 55px)",

                backgroundColor: "#c8c8c0",
              }}
            >
              {props.productOption.data_product_photos && props.productOption.data_product_photos.length > 0 ? (
                <MMSecureImage
                  api={props.api}
                  thumbnailUrl={`${props.productOption.data_product_photos[0]}/thumb.png`}
                  url={`${props.productOption.data_product_photos[0]}`}
                />
              ) : (
                <EuiImage className="image" alt="no image" url={NO_IMAGE} />
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={true}>{renderTitle(props.productOption, "s")}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFlexGroup gutterSize="xl">
          <EuiFlexItem grow={false}>
            <EuiFlexGrid gutterSize="s">
              <EuiFlexItem>
                <EuiFlexGrid gutterSize="s">
                  <EuiFlexItem grow={false}>
                    <EuiTitle size="xxs">
                      <EuiText color="grey" style={{ fontWeight: "300" }}>
                        {txt.get("orders.order.product")}
                        {isNotInPortfolio ? (
                          <span style={{ color: "#d71212" }}> {txt.lo("products.not_in_portfolio")}</span>
                        ) : (
                          <></>
                        )}
                      </EuiText>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem grow={true}>
                    {isNotInPortfolio ? (
                      <EuiFlexGrid gutterSize="s" style={{ minWidth: "200px" }}>
                        <EuiFieldText disabled={true} value={props.productOption.data_order_line.name} />
                        <EuiFieldText disabled={true} value={props.productOption.data_order_line.color} />
                        <EuiFieldText disabled={true} value={props.productOption.data_order_line.size} />
                      </EuiFlexGrid>
                    ) : (
                      <MMOrderProduct
                        isEditable={props.isEditable}
                        showTitle={false}
                        hideNonOptions={true}
                        direction="column"
                        products={props.products}
                        selection={selectionBeingDetailed}
                        order={props.order}
                        onChange={onProductChanged}
                      />
                    )}
                  </EuiFlexItem>
                  <EuiSpacer size="xs" />
                  <EuiSpacer size="xs" />
                  <EuiFlexItem grow={false}>
                    <EuiTitle size="xxs">
                      <EuiText color="grey" style={{ fontWeight: "300" }}>
                        {txt.get("orders.order.anatomy")}
                      </EuiText>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <MMOrderHand
                      isEditable={props.isEditable}
                      showTitle={false}
                      hideNonOptions={true}
                      products={props.products}
                      selection={selectionBeingDetailed}
                      order={props.order}
                      onChange={onProductChanged}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <MMOrderDigits
                      isEditable={props.isEditable}
                      showTitle={false}
                      hideNonOptions={true}
                      selection={selectionBeingDetailed}
                      order={props.order}
                      products={props.products}
                      onChange={onProductChanged}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFlexGroup gutterSize="s">
                      <EuiFlexItem grow={false}>
                        <MMOrderMeasurements
                          isEditable={props.isEditable}
                          showTitle={false}
                          titleLong={true}
                          hideNonOptions={true}
                          direction="column"
                          selection={selectionBeingDetailed}
                          order={props.order}
                          products={props.products}
                          onChange={onProductChanged}
                          measurementsShown={["j1", "s1"]}
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  {feat(Feature.OrdersNewIntakeScreens) && selectionBeingDetailed.variation ? (
                    <EuiFlexItem>
                      <EuiTitle size="xxs">
                        <EuiText color="grey" style={{ fontWeight: "300" }}>
                          {txt.get("orders.order.function_description")}
                        </EuiText>
                      </EuiTitle>
                      <EuiSpacer size="xs" />
                      <EuiFlexGroup gutterSize="s">
                        <EuiFlexItem grow={false}>
                          <MMOrderProductDescription
                            isEditable={props.isEditable}
                            showTitle={false}
                            selection={selectionBeingDetailed}
                            order={props.order}
                            onChange={onProductChanged}
                          />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                      <EuiFlexGroup gutterSize="s">
                        <EuiFlexItem grow={false}>
                          <MMOrderFunctionDescription
                            isEditable={props.isEditable}
                            showTitle={false}
                            selection={selectionBeingDetailed}
                            order={props.order}
                            products={props.products}
                            onChange={onProductChanged}
                          />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  ) : (
                    <></>
                  )}
                </EuiFlexGrid>
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiFlexItem>
          {isNeedsVisible ? (
            <EuiFlexItem grow={false}>
              {feat(Feature.OrdersNewIntakeScreens) ? (
                <EuiFlexGrid gutterSize="s">
                  <EuiTitle size="xxs">
                    <EuiText color="grey" style={{ fontWeight: "300" }}>
                      <MMTip text={txt.html("orders.order.i.previous_aid")}>
                        {txt.get("orders.order.previous_aid")}
                      </MMTip>
                    </EuiText>
                  </EuiTitle>
                  <MMOrderPreviousAid
                    isEditable={props.isEditable || props.isCorrigible || false}
                    maxWidth={"260px"}
                    selection={selectionBeingDetailed}
                    order={props.order}
                    onChange={onProductChanged}
                    hasValidation={props.hasValidation}
                    orderLineInputs={props.orderLineInputs}
                  />
                  <EuiSpacer size="s" />
                  <EuiTitle size="xxs">
                    <EuiText color="grey" style={{ fontWeight: "300" }}>
                      <MMTip text={txt.html("orders.order.i.production")}>
                        {txt.get("orders.order.notes_production")}
                      </MMTip>
                    </EuiText>
                  </EuiTitle>
                  <MMOrderIsModification
                    isEditable={props.isEditable}
                    order={props.order}
                    selection={selectionBeingDetailed}
                    onChange={onProductChanged}
                    products={props.products}
                  />
                  <MMOrderNotes
                    isEditable={props.isEditable}
                    maxWidth={"260px"}
                    selection={selectionBeingDetailed}
                    order={props.order}
                    onChange={onProductChanged}
                  />
                  <MMOrderLineAttachments
                    isEditable={props.isEditable}
                    order={props.order}
                    orderLineId={props.productOption?.data_order_line?.id}
                    selection={selectionBeingDetailed}
                    onChange={onProductChanged}
                  />
                </EuiFlexGrid>
              ) : (
                <EuiFlexGrid gutterSize="s">
                  <EuiFlexItem grow={false}>
                    <EuiTitle size="xxs">
                      <EuiText color="grey" style={{ fontWeight: "300" }}>
                        {txt.get("orders.order.notes_product_legacy")}
                      </EuiText>
                    </EuiTitle>
                  </EuiFlexItem>
                  <MMOrderUse
                    isEditable={props.isEditable}
                    isCorrigible={props.isCorrigible}
                    showTitle={false}
                    hideNonOptions={true}
                    selection={selectionBeingDetailed}
                    order={props.order}
                    products={props.products}
                    onChange={onProductChanged}
                  />
                  <EuiFlexItem grow={false}>
                    <MMOrderArgumentation
                      isEditable={props.isEditable || props.isCorrigible || false}
                      maxWidth={"260px"}
                      selection={selectionBeingDetailed}
                      order={props.order}
                      onChange={onProductChanged}
                      hasValidation={props.hasValidation}
                      orderLineInputs={props.orderLineInputs}
                      testId={"textarea-order-argumentation"}
                    />
                  </EuiFlexItem>
                  {props.showProductionDetails ? (
                    <EuiFlexItem grow={false} style={{ maxWidth: "260px" }}>
                      <MMOrderType
                        isEditable={props.isEditable}
                        onChange={onProductChanged}
                        orderTypes={props.orderTypes}
                        selection={selectionBeingDetailed}
                        order={props.order}
                        showTitle={false}
                      />
                    </EuiFlexItem>
                  ) : null}
                  {props.showProductionDetails ? (
                    <MMRemakeReason
                      isEditable={props.isEditable}
                      onChange={onProductChanged}
                      remakeReasons={props.remakeReasons}
                      selection={selectionBeingDetailed}
                      orderTypes={props.orderTypes}
                      order={props.order}
                      showTitle={false}
                    />
                  ) : null}
                  {props.showProductionDetails ? (
                    <MMOrderReplacementFor
                      isEditable={props.isEditable}
                      onChange={onProductChanged}
                      clientProductions={props.clientProductions}
                      selection={selectionBeingDetailed}
                      orderTypes={props.orderTypes}
                      order={props.order}
                      showTitle={false}
                    />
                  ) : null}
                </EuiFlexGrid>
              )}
            </EuiFlexItem>
          ) : (
            <EuiFlexItem grow={false}>
              <EuiFlexGrid gutterSize="s">
                <EuiTitle size="xxs">
                  <EuiText color="grey" style={{ fontWeight: "300" }}>
                    <MMTip text={txt.html("orders.order.i.production")}>
                      {txt.get("orders.order.notes_production")}
                    </MMTip>
                  </EuiText>
                </EuiTitle>
                <MMOrderIsModification
                  isEditable={props.isEditable}
                  order={props.order}
                  selection={selectionBeingDetailed}
                  onChange={onProductChanged}
                  products={props.products}
                />
                <MMOrderNotes
                  isEditable={props.isEditable}
                  maxWidth={"260px"}
                  selection={selectionBeingDetailed}
                  order={props.order}
                  onChange={onProductChanged}
                />
                <MMOrderLineAttachments
                  isEditable={props.isEditable}
                  order={props.order}
                  orderLineId={props.productOption?.data_order_line?.id}
                  selection={selectionBeingDetailed}
                  onChange={onProductChanged}
                />
              </EuiFlexGrid>
            </EuiFlexItem>
          )}
          {isNeedsVisible ? (
            <EuiFlexItem grow={false}>
              {feat(Feature.OrdersNewIntakeScreens) ? (
                <EuiFlexGrid gutterSize="s">
                  <EuiFlexItem grow={false}>
                    <EuiTitle size="xxs">
                      <EuiText color="grey" style={{ fontWeight: "300" }}>
                        {feat(Feature.OrdersNewIntakeScreens) ? (
                          <MMTip text={txt.html("orders.order.i.product")}>
                            {txt.get("orders.order.notes_product")}
                          </MMTip>
                        ) : (
                          txt.get("orders.order.notes_product_legacy")
                        )}
                      </EuiText>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <MMOrderArgumentation
                      isEditable={props.isEditable || props.isCorrigible || false}
                      maxWidth={"260px"}
                      selection={selectionBeingDetailed}
                      order={props.order}
                      onChange={onProductChanged}
                      hasValidation={props.hasValidation}
                      orderLineInputs={props.orderLineInputs}
                      testId={"textarea-order-argumentation"}
                    />
                  </EuiFlexItem>
                  {props.showProductionDetails ? (
                    <EuiFlexItem grow={false} style={{ maxWidth: "260px" }}>
                      <MMOrderType
                        isEditable={props.isEditable}
                        onChange={onProductChanged}
                        orderTypes={props.orderTypes}
                        selection={selectionBeingDetailed}
                        order={props.order}
                        showTitle={false}
                      />
                    </EuiFlexItem>
                  ) : null}
                  {props.showProductionDetails ? (
                    <MMRemakeReason
                      isEditable={props.isEditable}
                      onChange={onProductChanged}
                      remakeReasons={props.remakeReasons}
                      selection={selectionBeingDetailed}
                      orderTypes={props.orderTypes}
                      order={props.order}
                      showTitle={false}
                    />
                  ) : null}
                  {props.showProductionDetails ? (
                    <MMOrderReplacementFor
                      isEditable={props.isEditable}
                      onChange={onProductChanged}
                      clientProductions={props.clientProductions}
                      selection={selectionBeingDetailed}
                      orderTypes={props.orderTypes}
                      order={props.order}
                      showTitle={false}
                    />
                  ) : null}
                  <MMOrderUse
                    isEditable={props.isEditable}
                    isCorrigible={props.isCorrigible}
                    showTitle={false}
                    hideNonOptions={true}
                    selection={selectionBeingDetailed}
                    order={props.order}
                    products={props.products}
                    onChange={onProductChanged}
                  />
                </EuiFlexGrid>
              ) : (
                <EuiFlexGrid gutterSize="s">
                  <EuiTitle size="xxs">
                    <EuiText color="grey" style={{ fontWeight: "300" }}>
                      <MMTip text={txt.html("orders.order.i.production")}>
                        {txt.get("orders.order.notes_production")}
                      </MMTip>
                    </EuiText>
                  </EuiTitle>
                  <MMOrderIsModification
                    isEditable={props.isEditable}
                    order={props.order}
                    selection={selectionBeingDetailed}
                    onChange={onProductChanged}
                    products={props.products}
                  />
                  <MMOrderNotes
                    isEditable={props.isEditable}
                    maxWidth={"260px"}
                    selection={selectionBeingDetailed}
                    order={props.order}
                    onChange={onProductChanged}
                  />
                  <MMOrderLineAttachments
                    isEditable={props.isEditable}
                    order={props.order}
                    orderLineId={props.productOption?.data_order_line?.id}
                    selection={selectionBeingDetailed}
                    onChange={onProductChanged}
                  />
                </EuiFlexGrid>
              )}
            </EuiFlexItem>
          ) : props.showProductionDetails ? (
            <EuiFlexItem grow={false}>
              <EuiFlexGrid gutterSize="s">
                <EuiFlexItem grow={false}>
                  <EuiTitle size="xxs">
                    <EuiText color="grey" style={{ fontWeight: "300" }}>
                      {txt.get("orders.order.order_type_detail")}
                    </EuiText>
                  </EuiTitle>
                </EuiFlexItem>

                <EuiFlexItem grow={false} style={{ maxWidth: "260px" }}>
                  <MMOrderType
                    isEditable={props.isEditable}
                    onChange={onProductChanged}
                    orderTypes={props.orderTypes}
                    selection={selectionBeingDetailed}
                    order={props.order}
                    showTitle={false}
                  />
                </EuiFlexItem>

                <MMRemakeReason
                  isEditable={props.isEditable}
                  onChange={onProductChanged}
                  remakeReasons={props.remakeReasons}
                  selection={selectionBeingDetailed}
                  orderTypes={props.orderTypes}
                  order={props.order}
                  showTitle={false}
                />

                <MMOrderReplacementFor
                  isEditable={props.isEditable}
                  onChange={onProductChanged}
                  clientProductions={props.clientProductions}
                  selection={selectionBeingDetailed}
                  orderTypes={props.orderTypes}
                  order={props.order}
                  showTitle={false}
                />
              </EuiFlexGrid>
            </EuiFlexItem>
          ) : (
            <></>
          )}
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiFlexGroup justifyContent="flexEnd" gutterSize="xs">
          {isEditingOrderLine && !!props.onDelete ? (
            <EuiFlexItem
              grow={false}
              style={
                !props.onDuplicate
                  ? {
                      marginRight: "auto",
                    }
                  : {}
              }
            >
              <EuiButton
                size="s"
                color="text"
                style={{ minInlineSize: "35px" }}
                iconType="trash"
                onClick={(e: any) => {
                  if (props.onDelete) props.onDelete(selectionBeingDetailed, orderLineIndex);
                }}
                data-testid="delete-order-line-button"
              ></EuiButton>
            </EuiFlexItem>
          ) : (
            <></>
          )}
          {isEditingOrderLine && !!props.onDuplicate ? (
            <EuiFlexItem
              grow={false}
              style={{
                marginRight: "auto",
              }}
            >
              <EuiButtonEmpty
                size="s"
                style={{ minInlineSize: "35px" }}
                iconType="copy"
                data-testid="duplicate-order-line-button"
                onClick={(e: any) => {
                  if (props.onDuplicate) props.onDuplicate(selectionBeingDetailed, orderLineIndex);
                }}
              ></EuiButtonEmpty>
            </EuiFlexItem>
          ) : (
            <></>
          )}
          {props.onCancel ? (
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                size="s"
                onClick={(e: any) => {
                  if (props.onCancel) props.onCancel(selectionBeingDetailed, orderLineIndex);
                }}
                data-testid="products-product-editing-cancel"
              >
                {props.onSelect ? txt.get("generic.cancel") : txt.get("generic.close")}
              </EuiButtonEmpty>
            </EuiFlexItem>
          ) : (
            <></>
          )}

          {props.onSelect ? (
            <EuiFlexItem grow={false}>
              <EuiButton
                size="s"
                onClick={(e: any) => {
                  if (props.onSelect) {
                    props.onSelect(selectionBeingDetailed, orderLineIndex);
                  }
                }}
                fill={true}
                disabled={!isDetailedSelectionComplete}
                color="accent"
                data-testid="products-product-editing-completed"
              >
                {isEditingOrderLine ? txt.get("generic.ok") : txt.get("generic.add")}
              </EuiButton>
            </EuiFlexItem>
          ) : (
            <></>
          )}
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default MMOrderProductSelectDetails;
