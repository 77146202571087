import { EuiButton, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminNav } from "./admin";
import MMOrganisationsList from "./organisations-list";
function MMAdminOrganisations() {
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [subPages, setSubPages] = useState<PageLink[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/organisations"));
    };
    getSubPages();

    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (await AuthenticationHelper.hasPermission(["organisations#edit_all"])) {
        buttons.push(
          <EuiButton
            key="add_organisation"
            color="accent"
            size="s"
            fill
            iconType="plus"
            onClick={() => navigate("/admin/organisations/new")}
          >
            {txt.get("generic.add_new_x", txt.lo("admin.organisations.name"))}
          </EuiButton>
        );
      }
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo("admin.organisations.page_title")}`}
      subPages={subPages}
      backTo="/admin"
      backToText={txt.uf("generic.back_to", txt.get("admin.page_title"))}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        <MMOrganisationsList />
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminOrganisations;
