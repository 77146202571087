import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminNav } from "./admin";
import MMInvoiceMatching from "./invoice-matching";

function MMAdminInvoiceMatching() {
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [subActionsToShow, setSubActionsToShow] = useState<any[]>([]);

  const navigate = useNavigate();
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/invoices"));
    };

    getSubPages();
  }, []);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let subActions: any[] = [];
      if (await AuthenticationHelper.hasPermission(["invoices#edit_all", "invoices#read_all"])) {
        subActions.push(
          <EuiButton onClick={() => navigate("/admin/invoices")} iconType="documentation" size="s">
            {txt.get("admin.invoices.page_title")}
          </EuiButton>
        );
      }
      setSubActionsToShow(subActions);
      let buttons: any[] = [];
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  const subActions = () => {
    return subActionsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo("admin.invoices.matching.page_title")}`}
      subPages={subPages}
      backTo="/admin/invoices"
      backToText={txt.uf("generic.back_to", txt.get("admin.invoices.page_title"))}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiFlexGroup>
          {subActions().map((subActionBlock: any, i: number) => (
            <EuiFlexItem grow={false} key={`sub-action-block-${i}`}>
              {subActionBlock}
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
        <EuiSpacer />
        <MMInvoiceMatching />
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminInvoiceMatching;
