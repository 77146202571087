import txt from "helpers/text-helper";
import { Client } from "../client/client";

export const ACTIVE_COUNTRY_CODES: string[] = ["NL", "AT", "BE", "CH", "CZ", "DE", "ES", "IT", "RO", "UK", "US"];

export const DEFAULT_FORMATTED_ADDRESS_PARTS: string[] = [
  "salutation",
  "street_name",
  "street_number",
  "street_number_suffix",
  "postal_area",
  "city",
];
export const ADDRESS_EMPTY: Address = {
  id: null,
  street_name: null,
  street_number: null,
  street_number_suffix: null,
  postal_area: null,
  city: null,
  country: null,
  lat: null,
  lng: null,
};

export const PERSON_EMPTY: Person = {
  id: null,
  salutation: null,
  initials: null,
  first_name: null,
  infix: null,
  last_name: null,
};

export const ADDRESSEE_EMPTY: Addressee = {
  id: null,
  name: "",
  salutation: "",
  address: ADDRESS_EMPTY,
  person: PERSON_EMPTY,
  addressable_id: null,
  addressable_type: null,
};

export interface Person {
  id: number | null;
  salutation: string | null;
  initials: number | null;
  first_name: string | null;
  infix: string | null;
  last_name: string | null;
}

export interface Address {
  id: number | null;
  street_name: string | null;
  street_number: number | null;
  street_number_suffix: string | null;
  postal_area: string | null;
  city: string | null;
  country: string | null;
  lat: number | null;
  lng: number | null;
}

export interface Addressee {
  id: number | null;
  name: string | null;
  salutation: string | null;
  address: Address;
  person: Person;
  addressable_id: number | null;
  addressable_type: string | null;
}

export const addressee = (addressable: any, name?: string, i: number = 0) => {
  if (addressable && addressable.addressees && addressable.addressees.length > i) {
    let filtered: Addressee[] = addressable.addressees;

    if (name) {
      filtered = filtered.filter((addressee: Addressee) => addressee.name === name);
    }

    if (filtered.length > 0 && i < filtered.length) {
      return filtered[i];
    }
  }
  return null;
};

export const formattedAddressParts = (
  addressToFormat: Address | Client,
  parts: string[] = DEFAULT_FORMATTED_ADDRESS_PARTS,
  separator: string = " "
) => {
  let result: string[] = [];

  parts.forEach((part: string, i: number) => {
    switch (part) {
      case "street_name":
        if (addressToFormat.street_name) result.push(addressToFormat.street_name);
        break;
      case "street_number":
        if (addressToFormat.street_number) result.push(addressToFormat.street_number + "");
        break;
      case "street_number_suffix":
        if (addressToFormat.street_number_suffix) result.push(addressToFormat.street_number_suffix);
        break;
      case "postal_area":
        if ((addressToFormat as Address).postal_area) {
          result.push((addressToFormat as Address).postal_area || ""); //weird
        } else if ((addressToFormat as Client).postal_code) {
          result.push((addressToFormat as Client).postal_code);
        }
        break;
      case "city":
        if (addressToFormat.city) result.push(addressToFormat.city);
        break;
      case "country":
        if (addressToFormat.country) result.push(txt.get(`address.countries.${addressToFormat.country.toLowerCase()}`));
        break;
    }
  });

  return result.join(separator);
};

export const formattedAddress = (addressable: any, name?: string, i: number = 0) => {
  let result = addressee(addressable, name, i);
  if (result) {
    return formattedAddressParts(result.address);
  }
  return null;
};

export const address = (addressable: any, name?: string, i: number = 0) => {
  let result = addressee(addressable, name, i);
  if (result) {
    return result.address;
  }
  return null;
};

export const addressablePerson = (addressable: any, name?: string, i: number = 0) => {
  let result = addressee(addressable, name, i);
  if (result) {
    return result.person;
  }
  return null;
};

export const nameParts = (fullName: string) => {
  const nameParts: string[] = fullName.split(" ");
  const firstName: string = nameParts.length > 0 ? nameParts[0] : "";
  const lastName: string = nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";
  const infix: string = nameParts.length > 2 ? nameParts.splice(1, nameParts.length - 2).join(" ") : "";

  return { first_name: firstName, last_name: lastName, infix: infix };
};
