import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiIcon,
  EuiImage,
  EuiSpacer,
  EuiSplitPanel,
  EuiText,
  EuiTitle,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import APIHelper from "api/api-helper";
import MMSecureImage from "components/layouts/image/secure-image";
import { MMOrderLineProductInput } from "components/orders/order-entry/order-line-entry";
import txt from "helpers/text-helper";
import { Fragment, useEffect, useRef } from "react";
import { productNameMain, productNameSub } from "store/data/product/product";
import { OnProductSelect } from "./product-cards";

export const NO_IMAGE: string = "/images/no-image-no-border.jpg";

export interface MMProductCardProps {
  product: any;
  selectable?: boolean;
  onSelect?: OnProductSelect;
  onSubSelect?: OnProductSelect;
  onSelectIcon?: any;
  onSubSelectIcon?: any;
  api?: APIHelper;
  direction?: "row" | "column";
  className?: string;
  dueDate?: Date;
}

export const renderTitle = (
  product: any,
  size: "l" | "s" | "xs" | "xxs" | "xxxs" = "xxxs",
  inline: boolean = false
) => {
  const main: string = productNameMain(product.label);
  const sub: string = productNameSub(product.label);
  return (
    <EuiTitle size={size}>
      <EuiText size="xs">
        {main}
        {sub ? (
          inline ? (
            <span style={{ fontWeight: "300", lineHeight: "0.9rem" }}>&nbsp;{sub}</span>
          ) : (
            <EuiText
              size={size === "xxxs" ? "xs" : "s"}
              style={{
                fontWeight: "300",
                lineHeight: "1.2rem",
                paddingTop: "5px",
              }}
            >
              {sub}
            </EuiText>
          )
        ) : (
          <></>
        )}
      </EuiText>
    </EuiTitle>
  );
};

function MMProductCard(props: MMProductCardProps) {
  const isMobileScreen = useIsWithinBreakpoints(["xs"]);
  const isSmallScreen = useIsWithinBreakpoints(["s", "m", "l"]);
  const isMediumScreen = useIsWithinBreakpoints(["xl"]);

  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  const lang: string = txt.lang();

  const handleOnClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.onSelect) {
      props.onSelect(props.product, true);
      console.log("selecting", props.product);
    }
  };

  const handleOnSubClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.onSubSelect) {
      props.onSubSelect(props.product, true);
      console.log("sub selecting");
    }
  };
  const renderOrderLineDetails = (orderLine: MMOrderLineProductInput) => {
    // console.log("renderOrderLineDetails", orderLine);
    return (
      <Fragment>
        {orderLine && orderLine.variation ? (
          <EuiText size="xs" style={{ padding: "4px 0px" }}>
            {orderLine.variation[`general_location_${lang}`]}, {txt.get(`scanning.scans.scan.hand.${orderLine.hand}`)}{" "}
            {orderLine.digits && orderLine.digits?.length > 0 ? `(${orderLine.digits?.join(",")})` : <></>}
          </EuiText>
        ) : (
          <Fragment>
            <EuiText size="xs" color="red" style={{ padding: "4px 0px" }}>
              {txt.get("products.not_in_portfolio")}
            </EuiText>
            <EuiText size="xs" style={{ padding: "0px 0px" }}>
              {txt.get(`scanning.scans.scan.hand.${orderLine.hand}`)}
              {orderLine.digits && orderLine.digits?.length > 0 ? `(${orderLine.digits?.join(",")})` : <></>}
            </EuiText>
          </Fragment>
        )}
        {orderLine && orderLine.variation ? (
          <EuiHealth color={orderLine.variation.color_sample} textSize="xs">
            {orderLine.variation.translations[lang].color}
          </EuiHealth>
        ) : (
          <EuiText size="xs" color="grey" style={{ padding: "4px 0px" }}>
            {orderLine.color}
          </EuiText>
        )}
      </Fragment>
    );
  };

  const renderImage = () =>
    props.product.data_product_photos && props.product.data_product_photos.length > 0 ? (
      <MMSecureImage
        allowFullScreen={false}
        thumbnailUrl={`${props.product.data_product_photos[0]}/thumb.png`}
        url={
          !!props.onSelect ? `${props.product.data_product_photos[0]}/thumb.png` : props.product.data_product_photos[0]
        }
        api={props.api}
      />
    ) : (
      <EuiImage alt="No Image" className="image" src={NO_IMAGE} />
    );

  const className = () => {
    let result: string[] = ["product-card"];

    if (firstRender.current) {
      result.push("product-card-appearing");
    }
    if (props.className) {
      result.push(props.className);
    }
    return result.join(" ");
  };

  return props.direction && props.direction === "row" ? (
    <EuiFlexItem className={className()} onClick={props.onSubSelect ? undefined : handleOnClick}>
      {props.onSelectIcon || props.onSubSelectIcon ? (
        <div className="select-option-holder">
          {props.onSelectIcon ? (
            <div onClick={handleOnClick} className="select-option-main">
              <EuiIcon color="white" size="l" type={props.onSelectIcon} />
            </div>
          ) : (
            <></>
          )}
          {props.onSubSelectIcon ? (
            <div onClick={handleOnSubClick} className="select-option-sub">
              <EuiIcon color="white" type={props.onSubSelectIcon} />
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {renderTitle(props.product, "xxxs", true)}
      <EuiSpacer size="xs" />
      <EuiFlexGroup justifyContent="center" alignItems="center" gutterSize="none">
        <EuiFlexItem>
          {props.product.data_order_line ? renderOrderLineDetails(props.product.data_order_line) : <></>}
        </EuiFlexItem>
        <EuiFlexItem
          grow={false}
          style={{
            width: "50px",
            height: "calc(calc(150 / 138) * 50px)",
            backgroundColor: "#c8c8c0",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "7.5px",
          }}
        >
          {renderImage()}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
  ) : (
    <EuiSplitPanel.Outer
      className={`product-card${firstRender.current ? " product-card-appearing" : ""}`}
      grow={false}
      style={{ display: "inherit" }}
      onClick={props.onSubSelect ? undefined : handleOnClick}
    >
      <EuiSplitPanel.Inner grow={false} paddingSize="none" style={{ width: "100%" }}>
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          gutterSize="none"
          style={{
            height: "100%",
            width: "calc(100% + 10px)",
            position: "relative",
            left: "-5px",
          }}
        >
          <EuiFlexItem
            grow={true}
            style={{
              height: isMobileScreen
                ? "calc(calc(150 / 138) * 250px)"
                : isSmallScreen
                  ? "calc(calc(150 / 138) * 250px)"
                  : isMediumScreen
                    ? "calc(calc(150 / 138) * 220px)"
                    : "calc(calc(150 / 138) * 220px)",
              width: "100%",
              // aspectRatio: "auto 138 / 150",
            }}
          >
            {renderImage()}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner
        paddingSize="m"
        grow={false}
        style={{
          height: props.product.data_order_line ? "160px" : "60px",
          // overflow: "hidden",
          display: "block",
          // width: "100% !important",
          // maxWidth: "220px",
        }}
      >
        {renderTitle(props.product)}
        {props.product.data_order_line ? renderOrderLineDetails(props.product.data_order_line) : <></>}
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  );
}

export default MMProductCard;
