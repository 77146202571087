import env from "helpers/env-helper";
import APIHelper from "./api-helper";

import AuthenticationHelper from "helpers/authentication-helper";
import { Scanner } from "store/data/scan/scanner";
import { SCANNER_SORTABLE_FIELDS, SCAN_SORTABLE_FIELDS } from "./manox-api-field-options";
export interface ApiPagingInfo {
  limit: number;
  offset: number;
  count: number;
  total: number;
  since: string;
  until: string;
}

class ManoXAPIHelper extends APIHelper {
  private ACCESS_TOKEN_KEY = "cloud.manometric.api.manox.access_token";
  private API_ROOT = env("REACT_APP_API_MANOX");

  async preHook() {
    // await this.checkAuthentication();
  }

  async postHook() {}

  async checkAuthentication() {
    await AuthenticationHelper.refreshAuthentication();
    this.setAccessToken(AuthenticationHelper.getToken());
  }

  getRoot() {
    return this.API_ROOT;
  }

  getAccesstokenKey() {
    return this.ACCESS_TOKEN_KEY;
  }

  getUserCredentialsKey() {
    return null;
  }

  scanIsSortableBy(key: string) {
    return SCAN_SORTABLE_FIELDS.includes(key);
  }

  scannerIsSortableBy(key: string) {
    return SCANNER_SORTABLE_FIELDS.includes(key);
  }

  async getPermissions() {
    const path = `permissions`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    return result.result ? result.result : [];
  }

  async getOrderScans(orderId: number) {
    const path = "scans";

    let params = {
      order_number: orderId,
    };

    const result: any = await this.get(path, params);

    return result;
  }

  async getClientScans(clientCode: string) {
    const path = "scans";

    let params = {
      client: clientCode,
      sort: "captured_at",
      order: "desc",
    };

    const result: any = await this.get(path, params);

    return result;
  }

  async getScans(
    filters?: any,
    limit?: number,
    offset?: number,
    sort?: string,
    order?: string,
    since?: Date,
    until?: Date
  ) {
    const path = "scans";

    await this.checkAuthentication();

    let params = { limit, offset, sort, order, ...filters };
    if (since) params.since = this.toApiDate(since);
    if (until) params.until = this.toApiDate(until);

    const result: any = await this.get(path, params);

    return result;
  }

  async getScan(scanId: string) {
    const path = `scans/${scanId}`;

    await this.checkAuthentication();

    const result: any = await this.get(path);

    return result;
  }

  async getScanEvents(scanId: string) {
    const path = `scans/${scanId}/events?sort=created_at&order=desc`;

    const result: any = await this.get(path);

    return result;
  }

  async getScanAssets(scanId: string, type?: string) {
    const path = `scans/${scanId}/assets`;
    const params = { type: type ?? "" };

    await this.checkAuthentication();

    const result: any = await this.get(path, params);

    return result;
  }

  async getProcessingRun(scanId: string, runId: string) {
    const path = `scans/${scanId}/processing_run/${runId}`;
    console.log("getProcessingRun", path);
    const result: any = await this.get(path);

    return result;
  }

  async getProcessingRunAssets(scanId: string, runId: string, type?: string) {
    const path = `scans/${scanId}/processing_run/${runId}/assets`;
    const params = { type: type ?? "" };

    const result: any = await this.get(path, params);

    return result;
  }

  deviceToScanner(device: any) {
    return {
      ...device.device,

      id: device.id,
    };
  }

  devicesToScanners(devices: any[]) {
    return devices.map((device: any) => this.deviceToScanner(device));
  }

  async getScanners(filters?: any, limit?: number, offset?: number, sort?: string, order?: string) {
    const path = "devices";

    await this.checkAuthentication();

    let params = {
      limit,
      offset,
      sort,
      order,
      ...filters,
    };

    const result: any = await this.get(path, params);

    if (result.result) {
      return {
        ...result,
        result: this.devicesToScanners(result.result),
      };
    }

    return result;
  }

  async getScanner(scannerId: number) {
    const path = `devices/${scannerId}`;

    await this.checkAuthentication();

    const result: any = await this.get(path);
    if (result.result) {
      return {
        ...result,
        result: this.deviceToScanner(result.result),
      };
    }
    return result;
  }
  async createScanner(scanner: Scanner) {
    console.log("createScanner endpoint not implemented yet");
    //todo
    //todo
    return { status: "OK", result: { scanner_id: 0 }, message: "" };
  }

  async updateScanner(scannerId: number, location: string, description: string) {
    console.log("updateScanner endpoint only location & description");

    const path = `devices/${scannerId}/config`;
    const body = { device: { location, description }, configuration: {} };
    await this.checkAuthentication();

    const result: any = await this.post(path, undefined, body);

    return result;
  }

  async deleteScanner(scannerId: number) {
    console.log("deleteScanner endpoint not implemented yet");
    //todo
    return { status: "OK", result: {}, message: "" };
  }

  async addScanRequest(scannerId: number, orderId: number, hand: string, name: string) {
    const path = `scan_requests/${scannerId}`;
    const body = { order_number: orderId, name, hand, posture: "default" };
    await this.checkAuthentication();

    const result: any = await this.post(path, undefined, body);
    return result;
  }

  async deleteScanRequests(scannerId: number, orderId: number) {
    const path = `scan_requests/${scannerId}`;
    const params = { order_number: orderId };
    await this.checkAuthentication();

    const result: any = await this.delete(path, params);
    return result;
  }
}

export default ManoXAPIHelper;
