import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiText } from "@elastic/eui";
import { ReactNode } from "react";

export enum MMCellHealth {
  Success = "success",
  Warning = "warning",
  Danger = "danger",
  Neutral = "neutral",
}

export const DEFAULT_ALIGN = "left";
interface MMCellProps {
  text: string | ReactNode;
  size?: "s" | "xs" | "m";
  wrap?: boolean;
  onClick?: any;
  subText?: string | ReactNode;
  bold?: boolean;
  subBold?: boolean;
  subSoft?: boolean;
  health?: MMCellHealth;
  before?: any;
  after?: any;
  afterInline?: boolean;
  tag?: string;
  tagColor?: string;
  grow?: boolean;
  columnDirection?: "row" | "column";
  align?: "left" | "center" | "right";
  "data-testid"?: string;
}

function MMCell(props: MMCellProps) {
  return (
    <EuiFlexGroup
      data-testid={props["data-testid"]}
      style={!!props.onClick ? { cursor: "pointer" } : {}}
      onClick={props.onClick}
      gutterSize="none"
      alignItems={props.columnDirection === "column" ? "flexStart" : "center"}
      direction={props.columnDirection || "row"}
    >
      <EuiFlexItem grow={false}>
        {props.before ? <div style={{ marginRight: "7.5px" }}>{props.before}</div> : <></>}
      </EuiFlexItem>
      <EuiFlexItem grow={props.grow === undefined ? true : props.grow}>
        <EuiText textAlign={props.align || DEFAULT_ALIGN}>
          {props.health ? (
            <EuiHealth color={props.health} textSize={props.size || "s"}>
              {props.bold ? <strong>{props.text}</strong> : props.text}
            </EuiHealth>
          ) : (
            <EuiText
              size={props.size || "s"}
              style={props.wrap !== undefined && props.wrap === false ? { whiteSpace: "nowrap" } : {}}
            >
              {props.bold ? <strong>{props.text}</strong> : props.text}
              {props.after && props.afterInline === true ? <span>{props.after}</span> : <></>}
            </EuiText>
          )}
          {props.subText ? (
            <EuiText
              size={props.size || "s"}
              style={{
                fontWeight: "thin",
                ...(props.wrap !== undefined && props.wrap === false ? { whiteSpace: "nowrap" } : {}),
                ...(props.subSoft !== undefined && props.subSoft === true ? { color: "#aaa" } : {}),
              }}
            >
              {props.subBold ? <strong>{props.subText}</strong> : props.subText}
            </EuiText>
          ) : (
            <></>
          )}
        </EuiText>
      </EuiFlexItem>
      {props.tag ? (
        <EuiFlexItem grow={false}>
          <EuiBadge
            style={{
              marginLeft: "3px",

              fontSize: "8px",
            }}
            color={props.tagColor || "accent"}
          >
            {props.tag.toUpperCase()}
          </EuiBadge>
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {props.after && (props.afterInline === undefined || props.afterInline === false) ? (
        <EuiFlexItem grow={false}>{props.after}</EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGroup>
  );
}

export default MMCell;
