import {
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import MMReferrer from "components/admin/referrer";
import MMIcon from "components/layouts/icon/icon";
import MMTip from "components/layouts/tip/tip";
import { feat, Feature } from "feats";
import txt from "helpers/text-helper";
import { updateField } from "hoc/helper-hooks";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import { nameParts, PERSON_EMPTY } from "store/data/adress/address";
import { Organisation } from "store/data/organisation/organisation";
import { formalName } from "store/data/personal-details/personal-details";
import { Referral, REFERRAL_EMPTY } from "store/data/referral/referral";
import { Referrer, REFERRER_EMPTY } from "store/data/referrer/referrer";
import { useDebounce } from "use-debounce";
import { MMOrderEntryInputProps } from "../order-entry";

const SEARCH_DEBOUNCE_DELAY: number = 700;

const toSelectedOption = (optionId: number | string, options: any) => {
  let result: any;
  if (optionId && options) {
    result = options.find((option: any) => option.value === optionId);
  }
  return result;
};

function MMOrderReferrer(props: MMOrderEntryInputProps) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [referrers, setReferrers] = useState<any[]>([]);
  const [specialties, setSpecialties] = useState<any[]>([]);

  const [selectedOrganisation, setSelectedOrganisation] = useState<any>();
  const [organisationOptions, setOrganisationOptions] = useState<any[]>([]);
  const [selectedReferrer, setSelectedReferrer] = useState<any>();
  const [selectedSpecialty, setSelectedSpecialty] = useState<string>(props.order?.referral?.referrer?.specialty || "");
  const [specialtyOptions, setSpecialtyOptions] = useState<any[]>([]);
  const [referrerError, setReferrerError] = useState("");
  const [institutionError, setInsitutionError] = useState("");
  const [specialtyError, setSpecialtyError] = useState("");
  const [isDisabledReferral, setIsDisabledReferral] = useState(false);

  const [search, setSearch] = useDebounce("", SEARCH_DEBOUNCE_DELAY);

  const [isReferralDetailsOpen, setIsReferralDetailsOpen] = useState<boolean>(false);

  const [referrerOptions, setReferrerOptions] = useState<any[]>([]);

  const loadReferrerInfo = async (search?: string) => {
    console.log("loadReferrerInfo", search);
    setIsLoading(true);
    if (!props.api) {
      console.log("NO API");
      return;
    }
    const referrers: Referrer[] = await props.api.getReferrers(search);
    setReferrers(Array.isArray(referrers) ? referrers : []);

    const specialties: string[] = await props.api.getSpecialties();
    setSpecialties(Array.isArray(specialties) ? specialties : []);
    setIsLoading(false);
  };

  useEffect(() => {
    if (feat(Feature.OrdersDuplicateReferral)) {
      setIsDisabledReferral(!props.isFirstOrderOfReferral);
    }
  }, [props.isFirstOrderOfReferral]);

  useEffect(() => {
    loadReferrerInfo();
  }, []);

  useEffect(() => {
    loadReferrerInfo(search);
  }, [search]);

  useEffect(() => {
    let referrerOptions: Referrer[] = referrers;
    if (props.order?.referral?.referrer) {
      if (
        referrerOptions.findIndex(
          (referrerOption: Referrer) => referrerOption.id === props.order?.referral?.referrer?.id
        ) < 0
      ) {
        referrerOptions.push(props.order.referral.referrer);
      }
    }
    let options: any[] = referrers.map((referrer: Referrer) => ({
      label: `${referrer.person ? formalName(referrer.person) : ""} - ${referrer.alternative_organisation}`,
      value: referrer.id,
      data_name: referrer.person ? formalName(referrer.person) : "",
      data_last_name: referrer.person?.last_name,
      data_organisation: referrer.alternative_organisation,
    }));

    if (props.order?.referral?.referrer && props.order.referral.referrer.id === null) {
      options.push({
        label: `${
          props.order.referral.referrer.person ? formalName(props.order.referral.referrer.person) : ""
        } - ${props.order.referral.referrer.alternative_organisation}`,
        value: -1,
        data_name: props.order.referral.referrer.person ? formalName(props.order.referral.referrer.person) : "",
        data_last_name: props.order.referral.referrer.person?.last_name,
        data_organisation: props.order.referral.referrer.alternative_organisation,
      });
    }
    options.sort((a: any, b: any) =>
      a.data_last_name < b.data_last_name ? -1 : a.data_last_name > b.data_last_name ? 1 : 0
    );
    setReferrerOptions(options);
  }, [props.order, referrers]);

  useEffect(() => {
    if (props.order?.referral?.referrer_id) {
      setSelectedReferrer(toSelectedOption(props.order?.referral?.referrer_id, referrerOptions));
    } else if (props.order?.referral?.referrer?.person) {
      setSelectedReferrer(toSelectedOption(-1, referrerOptions));
    } else {
      setSelectedReferrer(null);
    }
  }, [props.order?.referral, referrerOptions]);

  const onReferrerChanged = (selectedReferrer: any, newReferrer?: Referrer) => {
    console.log("onReferrerChanged", selectedReferrer, newReferrer);
    let updatedReferral: Referral = props.order?.referral || REFERRAL_EMPTY;
    let updatedReferrer: Referrer = props.order?.referral?.referrer || REFERRER_EMPTY;

    if (!selectedReferrer) {
      updatedReferral.referrer_id = null;
      updatedReferral.referrer = null;
    } else if (selectedReferrer.value === -1) {
      updatedReferral.referrer_id = null;
      updatedReferral.referrer = {
        ...REFERRER_EMPTY,
        id: null,
        specialty: updatedReferrer.specialty,
        person: {
          ...PERSON_EMPTY,
          ...nameParts(selectedReferrer.label),
        },
      };
      console.log("make created one the updated", updatedReferral);
    } else if (selectedReferrer.value) {
      //shouldn't be empty though
      updatedReferral.referrer_id = selectedReferrer.value;
      updatedReferral.referrer = newReferrer
        ? { ...newReferrer }
        : {
            ...((referrers || []).find((referrer: Referrer) => referrer.id === selectedReferrer.value) ||
              REFERRER_EMPTY),
          };
    }

    if (props.onChange) {
      props.onChange(updateField(props.order, "referral", updatedReferral));
    } else {
      setSelectedReferrer(selectedReferrer);
    }
  };

  const onReferrerSubmit = async (referrer: Referrer) => {
    console.log("onReferrerSubmit", referrer);
    referrer.is_active = true;

    if (!props.api) {
      return;
    }

    let result;
    if (referrer.id) {
      result = await props.api.updateAdminReferrer(referrer.id, referrer);
    } else {
      result = await props.api.createAdminReferrer(referrer);
    }
    if (result.status === "OK") {
      dispatch(toastAdd(txt.get("generic.is_saved", txt.get("admin.referrers.name")), null, "success"));
      setReferrers([]);
      //await loadReferrerInfo();

      const updateResult = await props.api.getAdminReferrer(result.result.referrer_id);

      onReferrerChanged({ value: result.result.referrer_id }, updateResult.result);
    } else {
      dispatch(toastAdd(result.message ? result.message : txt.get("admin.referrers.error_saving"), null, "danger"));
    }

    setIsReferralDetailsOpen(false);
  };

  const onNewReferrer = (searchValue: string, flattenedOptions: EuiComboBoxOptionOption<unknown>[]) => {
    const normalizedSearchValue = searchValue.trim();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      value: -1,
      label: normalizedSearchValue,
    };

    if (flattenedOptions.findIndex((option) => option.label.trim().toLowerCase() === normalizedSearchValue) === -1) {
      onReferrerChanged(newOption);
    }
  };

  useEffect(() => {
    let options: any[] = specialties
      .filter((specialty: string) => !!specialty)
      .map((specialty: string) => ({
        label: specialty,
      }));

    //add custom option
    if (props.order?.referral?.referrer?.specialty && !options.includes(props.order.referral.referrer.specialty)) {
      options.push({
        label: props.order.referral.referrer.specialty,
      });
    }

    setSpecialtyOptions(options);
    if (props.order?.referral?.referrer?.specialty) {
      setSelectedSpecialty(props.order?.referral?.referrer?.specialty);
    } else {
      setSelectedSpecialty("");
    }

    if (props.inputs?.referrer) {
      setReferrerError(
        !!props.order?.referral?.referrer?.person?.first_name || !!props.order?.referral?.referrer?.person?.last_name
          ? ""
          : txt.get("validations.not_empty")
      );
      setInsitutionError(
        !!props.order?.referral?.referrer?.organisation_id ||
          !!props.order?.referral?.referrer?.alternative_organisation
          ? ""
          : txt.get("validations.not_empty")
      );
      setSpecialtyError(!!props.order?.referral?.referrer?.specialty ? "" : txt.get("validations.not_empty"));
    }
  }, [specialties, props.order]);

  const onSpecialtyChanged = (value: string) => {
    if (props.onChange) {
      props.onChange(
        updateField(
          props.order,
          "referral",
          updateField(
            props.order?.referral || REFERRAL_EMPTY,
            "referrer",
            updateField(props.order?.referral?.referrer || REFERRER_EMPTY, "specialty", value)
          )
        )
      );
    } else {
      setSelectedSpecialty(value);
    }
  };

  const onCreateSpecialty = (searchValue: string, flattenedOptions: any = []) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      label: searchValue,
    };
    if (
      flattenedOptions.findIndex((option: any) => option.label.trim().toLowerCase() === normalizedSearchValue) === -1
    ) {
      onSpecialtyChanged(newOption.label);
    }
  };

  useEffect(() => {
    if (!props.organisations) {
      setOrganisationOptions([]);
    } else {
      let options: any[] = props.organisations.map((organisation: Organisation) => ({
        label: organisation.name,
        value: organisation.id,
      }));

      //add custom option
      if (
        !props.order?.referral?.referrer?.organisation_id &&
        props.order?.referral?.referrer?.alternative_organisation
      ) {
        options.push({
          label: props.order.referral.referrer.alternative_organisation,
          value: -1,
        });
      }

      setOrganisationOptions(options);
    }
  }, [props.organisations, props.order]);

  useEffect(() => {
    if (props.order?.referral?.referrer?.organisation_id) {
      setSelectedOrganisation(toSelectedOption(props.order.referral.referrer.organisation_id, organisationOptions));
    } else if (props.order?.referral?.referrer?.alternative_organisation) {
      setSelectedOrganisation({
        label: props.order.referral.referrer.alternative_organisation,
        value: props.order.referral.referrer.alternative_organisation,
      });
    } else {
      setSelectedOrganisation(null);
    }
  }, [props.order?.referral, organisationOptions]);

  const onOrganisationChanged = (selectedOrganisation?: any) => {
    let updatedReferral: Referral = props.order?.referral || REFERRAL_EMPTY;
    let updatedReferrer: Referrer = props.order?.referral?.referrer || REFERRER_EMPTY;

    if (!selectedOrganisation) {
      updatedReferrer.organisation_id = null;
      updatedReferrer.alternative_organisation = "";
    } else if (selectedOrganisation.value === -1) {
      updatedReferrer.organisation_id = null;
      updatedReferrer.alternative_organisation = selectedOrganisation.label;
    } else if (selectedOrganisation.value) {
      updatedReferrer.organisation_id = selectedOrganisation.value;
      updatedReferrer.alternative_organisation = "";
    }
    updatedReferral.referrer = { ...updatedReferrer };

    if (props.onChange) {
      props.onChange(updateField(props.order, "referral", updatedReferral));
    } else {
      setSelectedReferrer(selectedOrganisation);
    }
  };

  const onCreateOrganisation = (searchValue: string, flattenedOptions: any = []) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      value: -1,
      label: searchValue,
    };

    if (
      flattenedOptions.findIndex((option: any) => option.label.trim().toLowerCase() === normalizedSearchValue) === -1
    ) {
      onOrganisationChanged(newOption);
    }
  };

  const handleReferrerSearch = useCallback((searchValue: string) => {
    setSearch(searchValue);
    // loadReferrerInfo(searchValue);
  }, []);

  const editingEnabled = (props.isEditable || props.isCorrigible || false) && !isDisabledReferral;

  return (
    <EuiFlexGroup
      gutterSize="s"
      direction="column"
      alignItems="flexStart"
      data-testid="order-referrer-inputs"
      data-referrer-id={selectedReferrer?.value || ""}
    >
      <EuiFlexItem grow={false}>
        <EuiTitle size="xs">
          <EuiText color="grey">
            <MMTip text={txt.html(`orders.order.i.referrer`)}>
              <span style={{ paddingRight: "8px" }}>
                <MMIcon type="referrer" />
              </span>
              {txt.get("admin.referrers.name")}
            </MMTip>
          </EuiText>
        </EuiTitle>
      </EuiFlexItem>
      <EuiHorizontalRule className="rule-strong" margin="none" />
      <EuiSpacer size="xs" />

      <EuiFlexItem grow={false}>
        <EuiFormRow
          isDisabled={!editingEnabled}
          className={!editingEnabled ? "readable-input product-input" : "product-input"}
          display="rowCompressed"
          isInvalid={props.showValidation && !!referrerError}
          error={referrerError}
        >
          <EuiComboBox
            isDisabled={!editingEnabled}
            className={!!selectedReferrer ? `option-selected referrer-options` : "referrer-options"}
            style={{ minWidth: "248px" }}
            color="accent"
            data-testid="referrer-name"
            isLoading={isLoading}
            singleSelection={{ asPlainText: true }}
            compressed={true}
            placeholder={txt.get("admin.referrers.name")}
            options={referrerOptions}
            onSearchChange={handleReferrerSearch}
            customOptionText={"asdasd"}
            selectedOptions={selectedReferrer ? [{ ...selectedReferrer, label: selectedReferrer.data_name }] : []}
            onChange={(selected) => onReferrerChanged(selected.length > 0 ? selected[0] : null)}
            onCreateOption={feat(Feature.OrdersReferrerDetails) ? undefined : onNewReferrer}
          />
        </EuiFormRow>
        <EuiSpacer size="s" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow
          isDisabled={!editingEnabled}
          className={!editingEnabled ? "readable-input product-input" : "product-input"}
          display="rowCompressed"
          isInvalid={props.showValidation && !!specialtyError}
          error={specialtyError}
        >
          <EuiComboBox
            isDisabled={!editingEnabled}
            className={!!selectedSpecialty ? `option-selected` : ""}
            singleSelection={{ asPlainText: true }}
            style={{ minWidth: "248px" }}
            compressed={true}
            data-testid="referrer-specialty"
            placeholder={txt.get("referrers.specialty")}
            options={specialtyOptions}
            selectedOptions={selectedSpecialty ? [{ label: selectedSpecialty }] : []}
            onChange={(selected) => onSpecialtyChanged(selected.length > 0 ? selected[0].label : "")}
            onCreateOption={feat(Feature.OrdersReferrerDetails) ? undefined : onCreateSpecialty}
          />
        </EuiFormRow>
        <EuiSpacer size="s" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow
          isDisabled={!editingEnabled}
          className={!editingEnabled ? "readable-input product-input" : "product-input"}
          display="rowCompressed"
          isInvalid={props.showValidation && !!institutionError}
          error={institutionError}
        >
          <EuiComboBox
            isDisabled={!editingEnabled}
            className={!!selectedOrganisation ? `option-selected` : ""}
            style={{ width: "248px" }}
            color="accent"
            data-testid="referrer-institution"
            isLoading={isLoading}
            singleSelection={{ asPlainText: true }}
            compressed={true}
            placeholder={txt.get("referrers.institution")}
            options={organisationOptions}
            selectedOptions={selectedOrganisation ? [selectedOrganisation] : []}
            onChange={(selected) => onOrganisationChanged(selected && selected.length > 0 ? selected[0] : null)}
            onCreateOption={feat(Feature.OrdersReferrerDetails) ? undefined : onCreateOrganisation}
          />
        </EuiFormRow>
        <EuiSpacer size="s" />
      </EuiFlexItem>
      {feat(Feature.OrdersReferrerDetails) ? (
        <EuiFlexItem>
          <EuiPopover
            id="new-referrer"
            button={
              <EuiButtonEmpty
                data-testid="button-details-referrer"
                disabled={!editingEnabled}
                style={{ opacity: 0.7 }}
                color="text"
                size="s"
                onClick={() => {
                  setIsReferralDetailsOpen(!isReferralDetailsOpen);
                }}
              >
                {selectedReferrer
                  ? txt.get("orders.order.referrer_details")
                  : txt.get("orders.order.cannot_find_referrer")}
              </EuiButtonEmpty>
            }
            isOpen={isReferralDetailsOpen}
            closePopover={() => {
              setIsReferralDetailsOpen(false);
            }}
            panelPaddingSize="none"
            anchorPosition="downRight"
            style={{ height: "100%" }}
          >
            <EuiPanel data-testid="panel-details-referrer">
              <MMReferrer
                formType="simple"
                fields={{
                  referrer: props.order?.referral?.referrer?.id ? { ...props.order.referral.referrer } : null,
                  specialties,
                }}
                handleSubmit={onReferrerSubmit}
              />
            </EuiPanel>
          </EuiPopover>
        </EuiFlexItem>
      ) : (
        <> </>
      )}
    </EuiFlexGroup>
  );
}

export default MMOrderReferrer;
