import {
  EuiBasicTable,
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
  EuiToolTip,
} from "@elastic/eui";
// import { useNavigate } from "react-router-dom";
import ConnectAPIHelper from "api/connect-api-helper";
import MMCell from "components/layouts/table/cell";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import { columnString } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import { Contract, toAGPHDescription } from "store/data/contract/contract";
import { useLocalStorage } from "store/local-storage";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "id";
export const DEFAULT_SORT_ORDER = "asc";

export interface ContractSelectHandler {
  (contract: Contract): void;
}
export interface MMContractListProps {
  selectable?: boolean;
  onSelect?: ContractSelectHandler;
}
function MMContractsList(props: MMContractListProps) {
  const api = new ConnectAPIHelper();

  const [insurers, setInsurers] = useState([]);
  const [contracts, setContracts] = useState([]);

  const [productFields, setProductFields] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage("contract_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("contract_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage("contract_sort_by", DEFAULT_SORT_BY);
  const [sortOrder, setSortOrder] = useLocalStorage("contract_sort_order", DEFAULT_SORT_ORDER);
  const [selectedInsurers, setSelectedInsurers] = useLocalStorage("contract_insurers", []);
  const [selectedContracts, setSelectedContracts] = useLocalStorage("contracts", []);
  const [selectedBaseArticleCode, setSelectedBaseArticleCode] = useLocalStorage("contracts_base_article_code", []);
  const [error, setError] = useState("");
  // const [isActiveValue, setIsActiveValue] = useState("_");

  useEffect(() => {
    const loadContracts = async () => {
      setIsLoading(true);

      let filters: any = {};
      if (selectedInsurers && selectedInsurers.length > 0) {
        filters.insurer_ids = selectedInsurers.map((insurer: any) => insurer["data-id"]);
      }

      if (selectedContracts && selectedContracts.length > 0) {
        filters.years = selectedContracts.map((option: any) =>
          option["label"] === txt.uf("generic.without_x", txt.get("admin.contracts.year")) ? "_" : option["label"]
        );
      }
      if (selectedBaseArticleCode && selectedBaseArticleCode.length > 0) {
        filters.base_article_codes = selectedBaseArticleCode.map((option: any) =>
          option["label"] === txt.uf("generic.without_x", txt.get("admin.contracts.base_article_code"))
            ? "_"
            : option["label"]
        );
      }

      const result = await api.getAdminContracts(filters, limit, offset, contractFieldToSortKey(sortBy), sortOrder);
      if (result.status === "OK") {
        setContracts(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setContracts([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadContracts();
  }, [
    sortOrder,
    sortBy,
    // search,
    limit,
    offset,
    selectedInsurers,
    selectedContracts,
    selectedBaseArticleCode,
  ]);

  useEffect(() => {
    const load = async () => {
      const insurers = await api.getAdminInsurers(undefined, undefined, "name", "asc");
      setInsurers(insurers.result);

      const productFields = await api.getProductFields();
      const lookup: any = {};
      for (let i = 0; i < productFields.length; i++) {
        const fields = productFields[i];
        lookup[fields.base_article_code] = { ...fields };
      }
      setProductFields(lookup);
    };
    load();
  }, []);

  const toInsurersSelectOptions = (list: any[]) => {
    if (!list) {
      return [];
    }

    return list
      .map((item) => ({
        label: item.name || "",
        "data-id": item.id,
      }))
      .concat([
        {
          label: txt.uf("generic.without_x", txt.get("admin.insurers.name")),
          "data-id": "_",
        },
      ]);
  };

  const onInsurerChange = (selectedOptions: any[]) => {
    setSelectedInsurers(selectedOptions);
  };

  const yearOptions = [
    { label: "2020" },
    { label: "2021" },
    { label: "2022" },
    { label: "2023" },
    { label: "2024" },
    { label: "2025" },
    {
      label: txt.uf("generic.without_x", txt.get("admin.contracts.year")),
    },
  ];

  const onContractChange = (selectedOptions: any[]) => {
    setSelectedContracts(selectedOptions);
  };

  const toSelectBaseArticleCode = (list: any[]) => {
    // TODO: get this list from api instead of filtering client side on page results
    // NOTE: we're not using the results from api.getProductFields because they don't
    // contain all possible article codes defined in the contracts.
    const articleCodes = Array.from(new Set<any>(list.map((item) => item.base_article_code)));

    return articleCodes
      .filter((code) => code !== null)
      .map((code) => ({ label: code }))
      .concat([
        {
          label: txt.uf("generic.without_x", txt.get("admin.contracts.base_article_code")),
        },
      ]);
  };

  const onArticleChange = (selectedOptions: any[]) => {
    setSelectedBaseArticleCode(selectedOptions);
  };

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.contracts.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0 ? txt.get("generic.all") : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.contracts.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const contractFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onContractsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const columns = [
    {
      name: txt.get("admin.contracts.id"),
      field: "id",
      sortable: api.contractIsSortableBy(contractFieldToSortKey("id")),
      style: { minWidth: "60px" },
    },
    {
      name: txt.get("admin.insurers.name"),
      field: "insurer.name",
      // sortable: api.contractIsSortableBy(contractFieldToSortKey("insurer_id")),
      render: (insurer_name: string) => <MMColumnFormatted value={columnString(insurer_name)} noWrap={true} />,
    },
    {
      name: txt.get("admin.contracts.year"),
      field: "year",
      sortable: api.referrerIsSortableBy(contractFieldToSortKey("year")),
      render: (year: string) => (
        <MMColumnFormatted
          value={columnString(year)}
          noWrap={true}
          // highlight={search}
        />
      ),
    },
    {
      name: txt.get("admin.contracts.base_article_code"),
      field: "base_article_code",
      sortable: api.contractIsSortableBy(contractFieldToSortKey("base_article_code")),
      render: (base_article_code: string) => (
        <MMColumnFormatted value={columnString(base_article_code)} noWrap={true} />
      ),
    },
    {
      name: txt.get("orders.order.product"),
      field: "base_article_code",

      render: (base_article_code: string) => (
        <MMColumnFormatted
          value={columnString(
            productFields[base_article_code] && productFields[base_article_code].product_name
              ? productFields[base_article_code].product_name
              : ""
          )}
          noWrap={true}
        />
      ),
    },

    {
      name: txt.get("admin.contracts.product_declaration_description"),
      field: "product_declaration_description",
      sortable: api.contractIsSortableBy(contractFieldToSortKey("product_declaration_description")),
      render: (product_declaration_description: string) => (
        <MMColumnFormatted value={columnString(product_declaration_description)} noWrap={true} />
      ),
    },
    {
      name: txt.get("admin.contracts.product_declaration_code"),
      field: "product_declaration_code",
      sortable: api.contractIsSortableBy(contractFieldToSortKey("product_declaration_code")),
      render: (product_declaration_code: string) => (
        <MMColumnFormatted value={columnString(product_declaration_code)} noWrap={true} />
      ),
    },
    {
      name: txt.get("admin.contracts.product_declaration_additional_codes"),
      field: "product_declaration_additional_code1",
      sortable: api.contractIsSortableBy(contractFieldToSortKey("product_declaration_code")),
      render: (product_declaration_additional_code1: string, contract: Contract) => (
        <MMCell
          text={
            contract.product_declaration_additional_code1
              ? contract.product_declaration_additional_code1.split(",").map((codePart: string, i: number) => (
                  <EuiToolTip key={`code-${i}`} position="top" content={toAGPHDescription(codePart)}>
                    <EuiText size="s">{codePart} </EuiText>
                  </EuiToolTip>
                ))
              : ""
          }
          subText={
            contract.product_declaration_additional_code2
              ? contract.product_declaration_additional_code2.split(",").map((codePart: string, i: number) => (
                  <EuiToolTip key={`code-${i}`} position="top" content={toAGPHDescription(codePart)}>
                    <EuiText size="s">{codePart} </EuiText>
                  </EuiToolTip>
                ))
              : ""
          }
        />
      ),
    },

    {
      name: txt.get("admin.contracts.insurer_product_code"),
      field: "insurer_product_code",
      sortable: api.contractIsSortableBy(contractFieldToSortKey("insurer_product_code")),
      render: (insurer_product_code: string) => (
        <MMColumnFormatted value={columnString(insurer_product_code)} noWrap={false} />
      ),
    },

    {
      name: txt.get("admin.contracts.invoice_line_description"),
      field: "invoice_line_description",
      sortable: api.referrerIsSortableBy(contractFieldToSortKey("invoice_line_description")),
      render: (invoice_line_description: string) => (
        <MMColumnFormatted value={columnString(invoice_line_description)} noWrap={false} />
      ),
    },
    {
      name: txt.get("admin.contracts.product_declaration_value"),
      field: "product_declaration_value",
      sortable: api.contractIsSortableBy(contractFieldToSortKey("product_declaration_value")),
      render: (product_declaration_value: string) => (
        <MMColumnFormatted
          value={columnString(product_declaration_value ? StringHelper.currency(product_declaration_value) : "-")}
          noWrap={true}
        />
      ),
    },
    {
      name: txt.get("admin.contracts.authorization"),
      field: "authorization",
      sortable: api.contractIsSortableBy(contractFieldToSortKey("authorization")),
      render: (authorization: boolean) => (
        <MMColumnFormatted
          value={
            authorization === true ? txt.get("generic.yes") : authorization === false ? txt.get("generic.no") : "-"
          }
          noWrap={true}
        />
      ),
    },
    {
      name: txt.get("admin.contracts.usage_term"),
      field: "usage_term",
      sortable: api.contractIsSortableBy(contractFieldToSortKey("usage_term")),
      render: (usage_term: string) => <MMColumnFormatted value={columnString(usage_term)} noWrap={false} />,
    },
  ];

  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem grow={false}>
          <EuiComboBox
            compressed={true}
            style={{ width: "225px" }}
            aria-label={txt.uf("generic.select_x", txt.get("admin.insurers.name"))}
            placeholder={txt.uf("generic.select_x", txt.get("admin.insurers.name"))}
            // singleSelection={{ asPlainText: true }}
            options={toInsurersSelectOptions(insurers)}
            selectedOptions={selectedInsurers}
            onChange={onInsurerChange}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiComboBox
            compressed={true}
            style={{ width: "200px" }}
            aria-label={txt.uf("generic.select_x", txt.get("admin.contracts.year"))}
            placeholder={txt.uf("generic.select_x", txt.get("admin.contracts.year"))}
            // singleSelection={{ asPlainText: true }}
            options={yearOptions}
            selectedOptions={selectedContracts}
            onChange={onContractChange}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiComboBox
            compressed={true}
            style={{ width: "225px" }}
            aria-label={txt.uf("generic.select_x", txt.get("admin.contracts.base_article_code"))}
            placeholder={txt.uf("generic.select_x", txt.get("admin.contracts.base_article_code"))}
            // singleSelection={{ asPlainText: true }}
            options={toSelectBaseArticleCode(contracts)}
            selectedOptions={selectedBaseArticleCode}
            onChange={onArticleChange}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiSpacer size="s" />
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={contracts}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        // rowProps={getRowProps}
        noItemsMessage={error ? error : txt.uf("generic.found_no_x", txt.get("admin.contracts.page_title"))}
        onChange={onContractsChange}
      />
    </Fragment>
  );
}

export default MMContractsList;
