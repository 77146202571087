import {
  EuiBasicTable,
  EuiButton,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiPanel,
  EuiPopover,
  EuiTab,
  EuiTabs,
  EuiText,
} from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { pagination } from "components/layouts/table/table-helpers";
import AuthenticationHelper from "helpers/authentication-helper";
import DateHelper from "helpers/date-helper";
import MomentHelper from "helpers/moment-helper";
import txt from "helpers/text-helper";
import { updateField } from "hoc/helper-hooks";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertConfirmActionState, confirmAsk, confirmGet } from "store/components/alert/confirm";
import { toastAdd } from "store/components/toast/toast";
import { EventLog, toEventDescription, toEventEmitterDescription } from "store/data/eventlog/eventlog";
import { isDefaultStatusLogComment, Order, orderStatusDescription, OrderStatusLog } from "store/data/order/order";
import { v4 as uuid } from "uuid";

const PAGE_SIZE_OPTIONS = [10, 20];
const DEFAULT_PAGE_SIZE = 10;

export interface MMOrderStatusLogsProps {
  order: Order;
}

function MMOrderStatusLogs(props: MMOrderStatusLogsProps) {
  const api = new ConnectAPIHelper();
  const [deleteRef] = useState("delete_order_" + uuid());

  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState<"events" | "status">("status");

  const [canEditOrderStatusLog, setCanEditOrderStatusLog] = useState(false);
  const [canSeeOrderStatusLog, setCanSeeOrderStatusLog] = useState(false);
  const [canSeeEventLog, setCanSeeEventLog] = useState(false);
  const [logs, setLogs] = useState<OrderStatusLog[]>([]);
  const [events, setEvents] = useState<EventLog[]>([]);
  const [editLogSelected, setEditLogSelected] = useState<number | null>(null);
  const [logToUpdate, setLogToUpdate] = useState<OrderStatusLog | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [order] = useState(props.order);

  const loadEventLogs = async () => {
    if (canSeeEventLog) {
      let filters: any = {};
      filters.object_type = "order";
      filters.object_id = order.id;
      const result: ApiResponse = await api.getEvents(filters, limit, offset, "emitted_at", "desc");
      if (result.status === ApiResponseStatus.OK) {
        setTotal(result.meta_data.result_set.total);
        setEvents(result.result);
      } else {
        setEvents([]);
      }
    } else {
      setEvents([]);
    }
  };

  const loadStatusLogs = async () => {
    if (canSeeOrderStatusLog) {
      const result: OrderStatusLog[] = await api.getOrderStatusLogs(order.id);
      setLogs(result || []);
    } else {
      setLogs([]);
    }
  };

  useEffect(() => {
    loadEventLogs();
  }, [canSeeEventLog, limit, offset]);

  useEffect(() => {
    loadStatusLogs();
  }, [canSeeOrderStatusLog]);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setCanEditOrderStatusLog(await AuthenticationHelper.hasPermission(["order_status_logs#edit_all"]));

      setCanSeeOrderStatusLog(
        await AuthenticationHelper.hasPermission([
          "order_status_logs#read",
          "order_status_logs#read_org",
          "order_status_logs#read_all",
        ])
      );
      setCanSeeEventLog(
        await AuthenticationHelper.hasPermission(["events#read", "events#read_org", "events#read_all"])
      );
    };
    setInterfaceForPermissions();
  }, []);

  useEffect(() => {
    if (editLogSelected) {
      setLogToUpdate(logs.find((log: OrderStatusLog) => log.id === editLogSelected) || null);
    } else {
      setLogToUpdate(null);
    }
  }, [editLogSelected]);

  const onLoggedAtChanged = (value: Moment | null) => {
    const loggedAt = DateHelper.toISO(MomentHelper.toDate(value));

    setLogToUpdate(updateField(logToUpdate, "logged_at", loggedAt));
  };

  const handleSaveLogToUpdate = async () => {
    setIsLoading(true);
    if (logToUpdate) {
      const result: ApiResponse = await api.updateOrderStatusLog(
        logToUpdate?.order_id,
        logToUpdate?.id,
        logToUpdate.logged_at,
        logToUpdate.comment
      );

      if (result && result.status === ApiResponseStatus.OK) {
        console.log("Log updated");
        setEditLogSelected(null);
        await loadStatusLogs();
      }
    } else {
      console.log("no log to update, doing nothing");
    }
    setIsLoading(false);
  };
  const handleLogDelete = async (orderId: number, orderStatusLogId: number) => {
    dispatch(
      confirmAsk(
        `${txt.uf("generic.delete_x", txt.get("orders.order.log"))}.`,
        txt.get("orders.order.log_entry_delete_confirm"),
        deleteRef,
        { order_id: orderId, order_status_log_id: orderStatusLogId }
      )
    );
  };

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (alertConfirm.actionState === AlertConfirmActionState.Perform && alertConfirm.actionKey === deleteRef) {
      deleteLog(alertConfirm.actionData.order_id, alertConfirm.actionData.order_status_log_id);
    }
  }, [alertConfirm]);

  const deleteLog = async (orderId: number, orderStatusLogId: number) => {
    setIsLoading(true);
    const result: ApiResponse = await api.deleteOrderStatusLog(orderId, orderStatusLogId);
    if (result && result.status === ApiResponseStatus.OK) {
      await loadStatusLogs();
    } else {
      dispatch(toastAdd(txt.get("generic.error"), result.message, "danger"));
    }
    setIsLoading(false);
  };

  const renderLogInfo = (log: any) => (
    <EuiFlexGroup direction="column" gutterSize="xs">
      {!isDefaultStatusLogComment(log.comment) ? (
        <EuiFlexItem>
          <EuiText size="xs">
            <i>{log.comment}</i>
          </EuiText>
        </EuiFlexItem>
      ) : (
        <></>
      )}
      <EuiFlexItem
        onClick={() => {
          setEditLogSelected(editLogSelected === log.id ? null : log.id);
        }}
        style={{ cursor: "pointer" }}
      >
        <EuiText size="xs">
          {DateHelper.ago(log.logged_at, txt.lang(), true)}&nbsp;
          {orderStatusDescription(log.new_status).toLowerCase()}&nbsp;
          {txt.lo("generic.by_x", log.user)}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const statuscolumns = () => [
    {
      name: `${txt.get("orders.order.log")} v.${props.order.version || 0}`,
      truncateText: false,
      width: "250px",
      render: (log: any) =>
        canEditOrderStatusLog ? (
          <EuiPopover
            id="edit-log"
            button={renderLogInfo(log)}
            isOpen={editLogSelected === log.id}
            closePopover={() => {
              setEditLogSelected(null);
            }}
            panelPaddingSize="none"
            anchorPosition="downRight"
            style={{ height: "100%" }}
          >
            <EuiPanel data-testid="order-status-log-edit">
              <EuiFormRow display="rowCompressed" label={"date"} data-testid="order-status-log-date-edit">
                <EuiDatePicker
                  dateFormat={"DD-MM-YYYY"}
                  compressed={true}
                  selected={
                    logToUpdate?.logged_at
                      ? MomentHelper.toMoment(DateHelper.parseDate(logToUpdate?.logged_at))
                      : undefined
                  }
                  onChange={onLoggedAtChanged}
                ></EuiDatePicker>
              </EuiFormRow>
              <EuiFormRow display="rowCompressed" label={"comment"}>
                <EuiFieldText
                  data-testid="order-status-log-comment-edit"
                  compressed={true}
                  onChange={(e: any) => {
                    setLogToUpdate(updateField(logToUpdate, "comment", e.target.value));
                  }}
                  value={logToUpdate?.comment}
                  style={{
                    opacity: isDefaultStatusLogComment(logToUpdate?.comment || "") ? 0.5 : 1.0,
                  }}
                />
              </EuiFormRow>
              <EuiButton
                size="s"
                data-testid="order-status-log-save"
                color="accent"
                onClick={(e: any) => {
                  handleSaveLogToUpdate();
                }}
              >
                {txt.get("generic.save")}
              </EuiButton>
            </EuiPanel>
          </EuiPopover>
        ) : (
          renderLogInfo(log)
        ),
    },
    {
      name: "",
      width: "30px",
      render: (log: any) => (
        <EuiIcon
          style={{ cursor: "pointer" }}
          onClick={
            !isLoading
              ? () => {
                  handleLogDelete(log.order_id, log.id);
                }
              : undefined
          }
          size="s"
          type={"cross"}
          aria-label="delete log"
        />
      ),
    },
  ];

  const eventcolumns = () => [
    {
      name: `${txt.get("generic.log_events")} v.${props.order.version || 0}`,
      truncateText: false,
      width: "250px",
      render: (event: EventLog) => (
        <EuiFlexGroup direction="column" gutterSize="xs">
          <EuiText size="xs">
            <i>{toEventDescription(event)}</i>
          </EuiText>
          <EuiText size="xs">
            {DateHelper.ago(event.emitted_at, txt.lang(), true)}&nbsp;
            {txt.lo("generic.by_x", toEventEmitterDescription(event))}
          </EuiText>
        </EuiFlexGroup>
      ),
    },
  ];

  const onEventsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
  };

  return (
    <EuiFlexItem
      style={{
        maxWidth: "280px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <EuiTabs size="s" expand={true}>
        {canSeeOrderStatusLog ? (
          <EuiTab
            onClick={(e: any) => {
              setCurrentTab("status");
            }}
            isSelected={currentTab === "status"}
          >
            {txt.get("generic.log_status")}
          </EuiTab>
        ) : (
          <></>
        )}

        {canSeeEventLog ? (
          <EuiTab
            onClick={(e: any) => {
              setCurrentTab("events");
            }}
            isSelected={currentTab === "events"}
          >
            {txt.get("generic.log_events")}
          </EuiTab>
        ) : (
          <></>
        )}
      </EuiTabs>
      {canSeeOrderStatusLog && currentTab === "status" && logs && logs.length > 0 ? (
        <EuiBasicTable
          itemId="id"
          compressed={true}
          items={logs}
          columns={statuscolumns()}
          noItemsMessage={txt.uf("generic.found_no_x", txt.lo("generic.log_status"))}
        />
      ) : canSeeEventLog && currentTab === "events" && events && events.length > 0 ? (
        <EuiBasicTable
          itemId="id"
          compressed={true}
          items={events}
          pagination={pagination(total, limit, offset, PAGE_SIZE_OPTIONS)}
          onChange={onEventsChange}
          columns={eventcolumns()}
          noItemsMessage={txt.uf("generic.found_no_x", txt.lo("generic.log_events"))}
        />
      ) : (
        <EuiPanel style={{ minWidth: "280px" }}>
          <EuiText size="s" textAlign="center">
            {txt.get("generic.no_results")}
          </EuiText>
        </EuiPanel>
      )}
    </EuiFlexItem>
  );
}

export default MMOrderStatusLogs;
